import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { tools } from "./tools";
import Constants from "./consts";
import ApiService from "./services/api.service";
import { VERIFY_AUTH } from "./store/auth.store";

require("dotenv").config();

Vue.prototype.tools = tools;
Vue.prototype.Constants = Constants;
Vue.config.productionTip = false;

// Global 3rd party plugins
import "bootstrap";
import "popper.js";
import "tooltip.js";
import "perfect-scrollbar";
import VueGeolocation from "vue-browser-geolocation";
import VueExcelXlsx from "vue-excel-xlsx";

Vue.use(VueGeolocation);
Vue.use(VueExcelXlsx);

import moment from "moment";
Vue.prototype.moment = moment;

import VueTelInput from "vue-tel-input";
Vue.use(VueTelInput);

import draggable from "vuedraggable";
Vue.use(draggable);
Vue.component("draggable", draggable);

import InputTag from "vue-input-tag";
Vue.component("input-tag", InputTag);

//Vue 3rd party plugins

// Our Plugins
import i18n from "./plugins/vue-i18n";
import vuetify from "./plugins/vuetify";
import swal from "./plugins/swal";
import VeeValidate from "./plugins/VeeValidate";
import googlemap from "./plugins/googlemap";
import components from "./plugins/components";
import customComponents from "./plugins/customComponents";
import "./plugins/bootstrap-vue";
import "./plugins/perfect-scrollbar";
import "./plugins/highlight-js";

//Vue GLOBAL Mixins
import mixins from "./mixins/Mixins";

import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import "vue-select/dist/vue-select.css";
//FILTERS
import formatSize from "./filters/size";

Vue.filter("formatSize", formatSize);

// API service init
ApiService.init();

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
window.onstorage = () => {
  // When local storage changes, dump the list to
  // the console.
  if (window.localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) == null) {
    router.push("/");
  }
};

new Vue({
  i18n,
  router,
  store,
  vuetify,
  swal,
  googlemap,
  VeeValidate,
  components,
  customComponents,
  mixins,
  render: h => h(App)
}).$mount("#app");
