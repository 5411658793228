export const AccountTypes = Object.freeze({
  Farmer: 1,
  Buyer: 2,
  Consultant: 3,
  LogisticCompany: 4,
  Laboratory: 5,
  Unknown: 99
});
export const DefinitionTypes = Object.freeze({
  CroplandType: 1,
  ProductCalibre: 2,
  ProductClass: 3,
  CropLandActions: 4,
  CropLandActionProducts: 5,
  FarmerFileGroups: 6,
  LandFileGroups: 7,
  CroplandFileGroups: 8,
  HarvestFileGroups: 9,
  ActionTypes: 10,
  BuyerTypes: 11,
  BuyerFileGroups: 12,
  HarvestRequestStatus: 13,
  HarvestRequestSubStatus: 14
});
export default { AccountTypes, DefinitionTypes };
