export default {
  APIUSERS: null,
  BUYERS: null,
  COMPANIES: null,
  CONSULTANTS: null,
  CROPLANDS: null,
  CROPLANDACTIONS: null,
  DEFINITIONS: null,
  ENDPOINTS: null,
  FARMERS: null,
  HARVESTS: null,
  LANDS: null,
  LANGUAGES: null,
  LOCATIONS: null,
  LOGISTICCOMPANIES: null,
  MODULES: null,
  MODULEGROUPS: null,
  PRODUCTS: null,
  PRODUCTKINDS: null,
  ROLES: null,
  SETTINGS: null,
  USERS: null,

  /*
	PRODUCT_MANAGEMENT: "fa fa-cubes",
	FARMER_MANAGEMENT: "fa fa-tractor",
	API_MANAGEMENT: "fa fa-project-diagram",
	SYSTEM_MANAGEMENT: "fa fa-cogs",
	CONSULTANT_MANAGEMENT: "fa fa-user-edit",
	BUYER_MANAGEMENT: "fa fa-user-plus",
	USER_MANAGEMENT: "fa fa-users",
	LOGISTIC_MANAGEMENT: "fa fa-truck",
	*/

  /* https://keenthemes.com/keen/preview/demo4/components/icons/flaticon.html */
  PRODUCT_MANAGEMENT: "flaticon-open-box",
  FARMER_MANAGEMENT: "flaticon-users",
  API_MANAGEMENT: "flaticon-map",
  SYSTEM_MANAGEMENT: "flaticon-cogwheel-2",
  CONSULTANT_MANAGEMENT: "flaticon-support",
  BUYER_MANAGEMENT: "flaticon-user-add",
  USER_MANAGEMENT: "flaticon2-user-1",
  LOGISTIC_MANAGEMENT: "flaticon2-delivery-truck"
};
