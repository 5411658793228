<template>
  <vue-select
    v-bind:value="value"
    v-on:input="valueChanged($event)"
    label="text"
    :filterable="false"
    :options="options"
    :multiple="multiple"
    @search="onSearch"
    @search:focus="onSearch"
    :placeholder="noOptionText"
    :reduce="option => option[valueLabel]"
  >
    <template slot="no-options">{{ noOptionText }}</template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center" v-if="value > 0 && options.length == 0">
        {{ text }}
      </div>
      <div class="selected d-center" v-else>{{ option.text }}</div>
    </template>
  </vue-select>
</template>

<script>
import _ from "lodash";
import i18n from "../../plugins/vue-i18n";

export default {
  name: "VSelect2",
  props: {
    multiple: Boolean,
    rest: Boolean,
    method: {
      default: "GET"
    },
    value: {
      default: 0
    },
    filters: {
      default: function() {
        return {};
      }
    },
    text: {
      default: ""
    },
    textLabel: {
      default: "text"
    },
    valueLabel: {
      default: "value"
    },
    textField: {
      default: "name"
    },
    valueField: {
      default: "id"
    },
    sort: {
      default: "name"
    },
    endPoint: {
      type: String,
      default: null
    },
    noOptionText: {
      type: String,
      default: i18n.t("GLOBAL.PLEASESELECT")
    },
    placeholder: {
      type: String,
      default: i18n.t("GLOBAL.PLEASESELECT")
    },
    size: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      options: [],
      lastSearch: null
    };
  },
  methods: {
    valueChanged($event) {
      this.$emit("input", $event);
      // this.$emit("onchange", this.value);
    },
    onSearchFocus(search, loading) {
      // console.log("onSearchFocus", search);
      this.onSearch(search, loading, true);
    },
    onSearch(search, loading, first) {
      // console.log("onSearch", search);

      if (
        typeof first == "undefined" ||
        first ||
        (search != "" && search != this.lastSearch)
      ) {
        // console.log("lastSearch", this.lastSearch);
        this.search(this, search, loading);
      }
    },
    search: _.debounce((vm, search, loading) => {
      // console.log("debounce", search);
      vm.doSearch(vm, search, loading);
    }, 350),
    doSearch: (vm, search, loading) => {
      if (typeof loading != "undefined") loading(true);
      let filters = vm.filters;
      if (typeof search != "undefined" && search != "") {
        filters["name"] = { value: "%" + search + "%" };
      }

      // console.log("doSearch", search);
      if (vm.rest) {
        vm.apiSend(vm.method, vm.endPoint).then(data => {
          vm.options = data.map(p => {
            let obj = {};
            obj[vm.textLabel] = p[vm.textField];
            obj[vm.valueLabel] = p[vm.valueField];
            return obj;
          });
          if (typeof loading != "undefined") loading(false);
          vm.lastSearch = search;
        });
      } else {
        vm.apiGetCombo(
          vm.endPoint,
          vm.valueLabel,
          vm.valueField,
          vm.textLabel,
          vm.textField,
          filters,
          vm.sort
        ).then(data => {
          vm.options = data;
          if (typeof loading != "undefined") loading(false);
          vm.lastSearch = search;
        });
      }
    }
  },
  mounted() {
    if (
      (Array.isArray(this.value) && this.value.length > 0) ||
      this.value > 0
    ) {
      // console.log("mounted");
      this.doSearch(this);
    }
  }
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  background-color: white;
}
</style>
