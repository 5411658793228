<template>
  <v-dialog
    fullscreen
    persistent
    v-model="show"
    @keydown.esc="closeForm"
    eager
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ t("GLOBAL.IMAGEEDITOR") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="saveImage">
            <v-icon size="small" class="mr-1">save</v-icon>
            {{ t("GLOBAL.SAVE") }}
          </v-btn>

          <v-btn dark text @click="closeForm">
            <v-icon size="small" class="mr-1">cancel</v-icon>
            {{ t("GLOBAL.CLOSE") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <b-container fluid>
          <b-row>
            <b-col cols="12" hidden>
              <b-button
                variant="success"
                @click="loadEditor(editingImage)"
                class="mr-2"
              >
                Load Image 1</b-button
              >
              <b-button variant="success" @click="saveImage" class="mr-2">
                Save Image</b-button
              >

              <b-button variant="danger" @click="destroyEditor" class="mr-2">
                Destroy Editor</b-button
              >
              <pre>{{ editingImage }}</pre>
            </b-col>
            <b-col cols="12" style="height: 900px;">
              <div ref="tuiImageEditor" style="width: 100%;height: 100%;"></div>
            </b-col>
          </b-row>
        </b-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeForm">
          <v-icon size="small" class="mr-1">cancel</v-icon>
          {{ t("GLOBAL.CLOSE") }}
        </v-btn>
        <v-btn color="primary" @click="saveImage">
          <v-icon size="small" class="mr-1">save</v-icon>
          {{ t("GLOBAL.SAVE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import "tui-color-picker/dist/tui-color-picker.css";
import "tui-image-editor/dist/tui-image-editor.css";
import ImageEditor from "tui-image-editor";

export default {
  name: "ImageEditor",
  data() {
    return {
      showEditor: false,
      customTheme: {
        "common.bi.image": "assets/media/logos/logo-light.png",
        "common.bisize.width": "180px",
        "common.bisize.height": "23px",
        "common.backgroundImage": "none",
        "common.backgroundColor": "#1e1e1e",
        "common.border": "0px",

        // header
        "header.backgroundImage": "none",
        "header.backgroundColor": "transparent",
        "header.border": "0px",

        // load button
        "loadButton.backgroundColor": "#fff",
        "loadButton.display": "none",
        "loadButton.border": "1px solid #ddd",
        "loadButton.color": "#222",
        "loadButton.fontFamily": "NotoSans, sans-serif",
        "loadButton.fontSize": "12px",

        // download button
        "downloadButton.backgroundColor": "#fdba3b",
        "downloadButton.display": "none",
        "downloadButton.border": "1px solid #fdba3b",
        "downloadButton.color": "#fff",
        "downloadButton.fontFamily": "NotoSans, sans-serif",
        "downloadButton.fontSize": "12px",

        // icons default
        "menu.normalIcon.color": "#8a8a8a",
        "menu.activeIcon.color": "#555555",
        "menu.disabledIcon.color": "#434343",
        "menu.hoverIcon.color": "#e9e9e9",
        "submenu.normalIcon.color": "#8a8a8a",
        "submenu.activeIcon.color": "#e9e9e9",

        "menu.iconSize.width": "24px",
        "menu.iconSize.height": "24px",
        "submenu.iconSize.width": "32px",
        "submenu.iconSize.height": "32px",

        // submenu primary color
        "submenu.backgroundColor": "#1e1e1e",
        "submenu.partition.color": "#858585",

        // submenu labels
        "submenu.normalLabel.color": "#858585",
        "submenu.normalLabel.fontWeight": "lighter",
        "submenu.activeLabel.color": "#fff",
        "submenu.activeLabel.fontWeight": "lighter",

        // checkbox style
        "checkbox.border": "1px solid #ccc",
        "checkbox.backgroundColor": "#fff",

        // rango style
        "range.pointer.color": "#fff",
        "range.bar.color": "#666",
        "range.subbar.color": "#d1d1d1",

        "range.disabledPointer.color": "#414141",
        "range.disabledBar.color": "#282828",
        "range.disabledSubbar.color": "#414141",

        "range.value.color": "#fff",
        "range.value.fontWeight": "lighter",
        "range.value.fontSize": "11px",
        "range.value.border": "1px solid #353535",
        "range.value.backgroundColor": "#151515",
        "range.title.color": "#fff",
        "range.title.fontWeight": "lighter",

        // colorpicker style
        "colorpicker.button.border": "1px solid #1e1e1e",
        "colorpicker.title.color": "#fff"
      }
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  props: {
    value: Boolean,
    editingImage: String
  },
  methods: {
    closeForm() {
      this.$emit("closeImageEditorClicked");
    },
    destroyEditor() {
      Object.keys(this.$listeners).forEach(eventName => {
        this.editorInstance.off(eventName);
      });
      this.editorInstance.destroy();
      this.editorInstance = null;
    },
    loadEditor(imgUrl) {
      this.editorInstance = new ImageEditor(this.$refs.tuiImageEditor, {
        includeUI: {
          loadImage: {
            path: imgUrl,
            name: "Blank"
          },
          theme: this.customTheme,
          menuBarPosition: "top"
        }
      });
      //this.editorInstance.ui.resizeEditor();
      //this.addEventListener();
    },
    saveImage() {
      let image = this.editorInstance.toDataURL({
        format: "jpeg"
      });
      this.apiSend("post", process.env.VUE_APP_UPLOAD_URL, { file: image });
    },
    addEventListener() {
      Object.keys(this.$listeners).forEach(eventName => {
        this.editorInstance.on(eventName, (...args) =>
          this.$emit(eventName, ...args)
        );
      });
    },
    getRootElement() {
      return this.$refs.tuiImageEditor;
    },
    invoke(methodName, ...args) {
      let result = null;
      if (this.editorInstance[methodName]) {
        result = this.editorInstance[methodName](...args);
      } else if (methodName.indexOf(".") > -1) {
        const func = this.getMethod(this.editorInstance, methodName);

        if (typeof func === "function") {
          result = func(...args);
        }
      }

      return result;
    },
    getMethod(instance, methodName) {
      const { first, rest } = this.parseDotMethodName(methodName);
      const isInstance = instance.constructor.name !== "Object";
      const type = typeof instance[first];
      let obj;

      if (isInstance && type === "function") {
        obj = instance[first].bind(instance);
      } else {
        obj = instance[first];
      }

      if (rest.length > 0) {
        return this.getMethod(obj, rest);
      }

      return obj;
    },
    parseDotMethodName(methodName) {
      const firstDotIdx = methodName.indexOf(".");
      let firstMethodName = methodName;
      let restMethodName = "";

      if (firstDotIdx > -1) {
        firstMethodName = methodName.substring(0, firstDotIdx);
        restMethodName = methodName.substring(
          firstDotIdx + 1,
          methodName.length
        );
      }

      return {
        first: firstMethodName,
        rest: restMethodName
      };
    }
  },
  watch: {
    show(value) {
      this.showEditor = value;
    },
    showEditor(val) {
      if (val) {
        this.loadEditor(this.editingImage);
      } else {
        this.destroyEditor();
      }
    }
  },
  mounted() {
    this.editorInstance = new ImageEditor(this.$refs.tuiImageEditor, {
      includeUI: {
        theme: this.customTheme,
        menuBarPosition: "top"
      }
    });
  },
  destroyed() {
    Object.keys(this.$listeners).forEach(eventName => {
      this.editorInstance.off(eventName);
    });
    this.editorInstance.destroy();
    this.editorInstance = null;
  }
};
</script>
