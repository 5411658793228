<template>
  <div>
    <GmapMap
      ref="gmap"
      :options="mapOptions"
      :center="{
        lat: latSelected,
        lng: lngSelected
      }"
      @rightclick="change"
      :zoom="zoom"
      :map-type-id="mapTypeId"
      :style="mapStyle"
    >
      <GmapMarker
        :position="{
          lat: latSelected,
          lng: lngSelected
        }"
        :draggable="true"
        @dragend="change"
      />

      <GmapPolygon
        :paths="paths"
        :editable="false"
        @paths_changed="updateEdited($event)"
      >
      </GmapPolygon>
    </GmapMap>
    <b-form-group
      :label="t('GLOBAL.COORDINATES')"
      size="sm"
      class="mt-3"
      v-if="!drawPolygons"
    >
      <b-input-group>
        <b-form-input :value="latSelected"></b-form-input>
        <b-form-input :value="lngSelected"></b-form-input>
      </b-input-group>
    </b-form-group>

    <ul v-if="polygons" @click="polygons = null">
      <li v-for="(path, i) in polygons" :key="i">
        <ol>
          <li v-for="(point, x) in path" :key="x">
            {{ point.lat }}, {{ point.lng }}
          </li>
        </ol>
      </li>
    </ul>
  </div>
</template>
<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA6NASevUWmAw1SFpaat3J17FzXuDVhoCY"></script>
<script>
import * as VueGoogleMaps from "vue2-google-maps";
import Vue from "vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyA6NASevUWmAw1SFpaat3J17FzXuDVhoCY",
    libraries: "places" //// If you need to use place input
  }
});

export default {
  name: "Map",
  props: {
    paths: Array,
    drawPolygons: {
      type: Boolean,
      default: false
    },
    lat: {
      default: 0
    },
    lng: {
      default: 0
    },
    mapOptions: {
      type: Object,
      default: () => {
        return {
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: false,
          fullScreenControl: false,
          disableDefaultUi: false
        };
      }
    },
    zoom: {
      type: Number,
      default: 6
    },
    mapTypeId: {
      type: String,
      default: "roadmap"
    },
    mapStyle: {
      type: String,
      default: "width: 100%; height: 400px"
    },
    markerPosition: {
      type: Object,
      default: () => {
        return {
          lat: 0,
          lng: 0
        };
      }
    }
  },
  data() {
    return {
      polygons: null,
      latSelected: 0,
      lngSelected: 0
    };
  },

  methods: {
    change(data) {
      this.latSelected = data.latLng.lat();
      this.lngSelected = data.latLng.lng();
      this.$emit("change", {
        lat: this.latSelected,
        lng: this.lngSelected
      });
    },
    resetIfRequired() {
      if (this.latSelected == 0 && this.lngSelected == 0) {
        this.$getLocation({
          enableHighAccuracy: true, //defaults to false
          timeout: Infinity, //defaults to Infinity
          maximumAge: 0 //defaults to 0
        }).then(coords => {
          this.latSelected = coords.lat;
          this.lngSelected = coords.lng;
        });
      }
    },
    mapFitBounds() {
      let vm = this;
      this.$refs.gmap.$mapPromise.then(map => {
        const b = new google.maps.LatLngBounds();
        vm.paths.forEach(point => {
          b.extend({ lat: point.lat, lng: point.lng });
        });
        map.fitBounds(b);
      });
    }
  },
  watch: {
    lat(val) {
      this.latSelected = val;
      this.resetIfRequired();
    },
    lng(val) {
      this.lngSelected = val;
      this.resetIfRequired();
    }
  },
  mounted() {
    this.latSelected = this.lat;
    this.lngSelected = this.lng;
    if (this.paths && this.paths.length > 0) this.mapFitBounds();
    this.resetIfRequired();
  }
};
</script>
