import jwt from "jsonwebtoken";
import menuIconMap from "@/config/menu.icon.map";
import ApiService from "../services/api.service";

// mutation types
export const SET_MENU = "setMenu";
import i18nService from "@/services/i18n.service.js";
let eosMenus = [];
const state = {
  menuItems: null,
};

const getters = {
  currentMenu(state) {
    return state.menuItems;
  },
};

const mutations = {
  [SET_MENU](state, token) {
    let userData = jwt.decode(token);
    let roleModules = userData.role.roleModules;
    let sections = [];
    sections.push({
      id: "0",
      title: "Dashboard",
      root: true,
      icon: "flaticon-home-2",
      page: "dashboard",
      translate: "GLOBAL.DASHBOARD",
      bullet: "dot",
    });
    if (eosMenus.length == 0) {
      ApiService.send("get", "eostoken").then((response) => {
        if (response.data.eostoken) {
          eosMenus = [];
          let links = String(process.env.VUE_APP_EOS_LINKS);
          let linksArr = links.split(",");
          for (const ind in linksArr) {
            let linkArr = linksArr[ind].split("|");
            eosMenus.push({
              page:
                linkArr[1].replace("https:/", "") +
                "&access_token=" +
                response.data.eostoken,
              title: linkArr[0],
              target: "_blank",
            });
          }
          sections.splice(1, 0, {
            title: "EOS Dashboard",
            translate: "GLOBAL.EOSREPORTS",
            bullet: "dot",
            root: true,
            toggle: "click",
            icon: "flaticon2-dashboard",
            submenu: eosMenus,
          });
          state.menuItems = sections;
        }
      });
    } else {
      sections.splice(1, 0, {
        title: "EOS Dashboard",
        translate: "GLOBAL.EOSREPORTS",
        bullet: "dot",
        root: true,
        toggle: "click",
        icon: "flaticon2-dashboard",
        submenu: eosMenus,
      });
      state.menuItems = sections;
    }

    roleModules.forEach((item) => {
      if (item.visible) {
        let section;
        let foundSections = sections.filter(
          (p) => p.id == item.module.moduleGroup.id
        );
        if (foundSections.length > 0) {
          section = foundSections[0];
        } else {
          section = {
            id: item.module.moduleGroup.id,
            title:
              i18nService.getActiveLanguage() == "tr"
                ? item.module.moduleGroup.name
                : item.module.moduleGroup.name_En,
            bullet: "dot",
            root: true,
            toggle: "click",
            icon: menuIconMap[item.module.moduleGroup.code.toUpperCase()] ?? "",
            submenu: [],
          };
          sections.push(section);
        }

        section.submenu.push({
          title:
            i18nService.getActiveLanguage() == "tr"
              ? item.module.name
              : item.module.name_En,
          page: item.module.endPoint,
          icon: menuIconMap[item.module.endPoint.toUpperCase()] ?? "",
        });
      }
    });

    sections.push({
      title: "Panel Settings",
      root: true,
      icon: "flaticon2-dashboard",
      page: "panelsettings/13",
      translate: "GLOBAL.PANELSETTINGS",
      bullet: "dot",
    });
    state.menuItems = sections;
  },
};
export default {
  state,
  mutations,
  getters,
};
