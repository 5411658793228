<template>
  <editor
    :api-key="apiKey"
    :init="config"
    v-bind:value="value"
    v-on:input="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: "TinyEditor",
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: () => {
        return {
          height: 200,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount code"
          ],
          toolbar:
            "code | undo redo | formatselect | bold italic backcolor | \
								   alignleft aligncenter alignright alignjustify | \
								   bullist numlist outdent indent | removeformat | help "
        };
      }
    }
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_TINY_MCE_KEY
    };
  }
};
</script>
