<template>
  <div>
    <b-row>
      <b-col cols="12">
        <vue-dropzone
          ref="myVueDropzone"
          id="dropzone"
          :options="options"
          @vdropzone-success="addedFiles"
          :useCustomSlot="true"
          v-if="multiple || (!multiple && !value)"
        >
          <div class="dropzone-custom-content">
            <h3 class="dropzone-custom-title">{{ title }}</h3>
            <div class="subtitle">{{ subTitle }}</div>
            <div class="subtitle" v-if="secondaryTitle">
              {{ secondaryTitle }}
            </div>
          </div>
        </vue-dropzone>
        <ImageEditorComponent
          v-model="showImageEditor"
          :editing-image="editingImage"
          @closeImageEditorClicked="closeImageEditor"
        ></ImageEditorComponent>
      </b-col>
    </b-row>
    <b-row v-if="!multiple && value">
      <b-col cols="12">
        <b-form-group>
          <b-img
            :src="value"
            width="300"
            height="200"
            class="img-fluid"
          ></b-img>
          <div class="clearfix" v-if="value"></div>

          <v-btn
            color="primary"
            class="mt-1 mr-5"
            @click="openImageEditor(value)"
            ><v-icon x-small dark class="mr-1">fa fa-palette</v-icon>
            {{ t("GLOBAL.ITEM_EDIT") }}
          </v-btn>

          <v-btn
            color="error"
            class="mt-1"
            @click="deleteSingleFile(value, code)"
            ><v-icon x-small dark class="mr-1">fa fa-trash</v-icon>
            {{ t("GLOBAL.ITEM_DELETE") }}
          </v-btn>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="multiple && value">
      <b-col cols="2" v-for="file in value" :key="file.link">
        <b-card :img-src="cardImg(file)">
          <b-card-text>
            <small hidden>{{ file.name }}</small>
            <b-form-group :label="t('GLOBAL.DESCRIPTION')">
              <b-form-textarea v-model="file.description"></b-form-textarea>
            </b-form-group>
          </b-card-text>

          <!--          <v-btn-->
          <!--              color="primary"-->
          <!--              x-small-->
          <!--              v-if="file.type == 'image'"-->
          <!--              @click="openImageEditor(file)"-->
          <!--          >-->
          <!--            <v-icon x-small>fa fa-pen</v-icon>-->
          <!--          </v-btn>-->

          <v-btn
            :href="file.link"
            target="_blank"
            color="primary"
            x-small
            v-if="file.type == 'video'"
          >
            <v-icon x-small>fa fa-play</v-icon>
          </v-btn>

          <v-btn
            :href="file.link"
            target="_blank"
            color="primary"
            x-small
            v-if="file.type == 'file'"
          >
            <v-icon x-small>fa fa-search</v-icon>
          </v-btn>

          <v-btn
            color="error"
            x-small
            class="pull-right"
            @click="deleteMultipleFile(file.link, code)"
          >
            <v-icon x-small>fa fa-trash</v-icon>
          </v-btn>
          <b-form-checkbox size="lg" switch v-model="file.active" name="active"
            >{{ t("GLOBAL.ACTIVE") }}
          </b-form-checkbox>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import i18n from "../../plugins/vue-i18n";
import ImageEditorComponent from "./ImageEditorComponent";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    ImageEditorComponent
  },
  props: {
    value: {
      default: null
    },
    code: {
      default: null
    },
    multiple: Boolean,
    title: {
      type: String,
      default: i18n.t("GLOBAL.IMAGE")
    },
    subTitle: {
      type: String,
      default: i18n.t("GLOBAL.DROPIMAGESTOUPLOAD")
    },
    secondaryTitle: {
      type: String,
      default: i18n.t("GLOBAL.CLICKIMAGESTOUPLOAD")
    }
  },
  data() {
    return {
      editingImage: null,
      showImageEditor: false,
      options: {
        //TODO  Yakubun metoda gönderilecek
        url: process.env.VUE_APP_UPLOAD_API,
        thumbnailWidth: 150,
        maxFilesize: 10,
        headers: { "Extra-Header": "header value" },
        uploadMultiple: this.multiple
      }
    };
  },
  methods: {
    openImageEditor(file) {
      this.editingImage = file;
      this.showImageEditor = true;
    },
    closeImageEditor() {
      this.editingImage = null;
      this.showImageEditor = false;
    },

    addedFiles(response) {
      //TODO  Yakubun metoddan gelen cevap işlenecek
      if (response.xhr.response != "") {
        let files = JSON.parse(response.xhr.response);
        if (!this.multiple) {
          files.forEach(f => {
            f.filename = process.env.VUE_APP_UPLOAD_URL + "/" + f.filename;
          });
          this.change(files[0].filename, this.code);
        } else {
          files.forEach(f => {
            f.type = this.setFileType(f.mimetype);
            f.link = process.env.VUE_APP_UPLOAD_URL + "/" + f.filename;
            f.size = this.$options.filters.formatSize(f.size);
            f.active = true;
            f.date = new Date();
            f.name = f.filename;
          });
          this.change(files, this.code);
          this.$emit("addedFiles", files, this.code);
        }
      }
      this.$refs.myVueDropzone.removeAllFiles(true);
    },
    change(files, code) {
      this.$emit("input", files, code);
    },
    deleteSingleFile(link, code) {
      this.deleteFileFromServer(link).then(() => {
        this.change(null, code);
      });
    },
    deleteMultipleFile(link, code) {
      this.deleteFileFromServer(link).then(() => {
        this.$emit("deletedFile", link, code);
      });
    },
    cardImg(file) {
      if (file.type == "image") {
        return file.link;
      } else if (file.type == "video") {
        return "https://cdn.icon-icons.com/icons2/1713/PNG/512/iconfinder-videologoplayicon-3993847_112649.png";
      } else if (file.type == "file") {
        return "https://cdn3.iconfinder.com/data/icons/brands-applications/512/File-512.png";
      } else {
        return null;
      }
    },
    setFileType(mimetype) {
      let fileType = mimetype.split("/");
      if (fileType[0] == "image") {
        return "image";
      } else if (fileType[0] == "video") {
        return "video";
      } else {
        return "file";
      }
    }
  }
};
</script>

<style>
.vue-dropzone {
  border: 2px dashed #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}
</style>
