<template>
  <v-dialog
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    v-model="show"
    @keydown.esc="closeForm"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <slot name="title" v-bind:item="item"></slot>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <slot name="formTopActions" v-bind:item="item"></slot>
          <v-btn
            dark
            text
            v-if="
              (item.hasOwnProperty(moduleData.primaryKey) && permissions.put) ||
                (!item.hasOwnProperty(moduleData.primaryKey) &&
                  permissions.post)
            "
            @click="$emit('saveItemClick')"
            ><v-icon size="small" class="mr-1">save</v-icon
            >{{ t("GLOBAL.SAVE") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <slot name="form" v-bind:item="item"></slot>
      </v-card-text>
      <v-card-actions>
        <slot name="formActionsLeft" v-bind:item="item"></slot>
        <v-spacer></v-spacer>
        <slot name="formActions" v-bind:item="item"></slot>
        <v-btn color="error" @click="closeForm">
          <v-icon size="small" class="mr-1">cancel</v-icon>
          {{ t("GLOBAL.CANCEL") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="$emit('saveItemClick')"
          v-if="
            (item.hasOwnProperty(moduleData.primaryKey) && permissions.put) ||
              (!item.hasOwnProperty(moduleData.primaryKey) && permissions.post)
          "
        >
          <v-icon size="small" class="mr-1">save</v-icon>
          {{ t("GLOBAL.SAVE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Form",
  inject: ["$validator"],
  mixins: [],
  data() {
    return {};
  },
  props: {
    value: Boolean,
    moduleData: {
      default: {}
    },
    item: {
      default: {}
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  methods: {
    closeForm() {
      this.$emit("closeItemEditFormClicked");
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    maxWidth() {
      return this.moduleData.form && this.moduleData.form.maxWidth
        ? this.moduleData.form.maxWidth
        : "auto";
    },
    fullscreen() {
      return this.moduleData.form && this.moduleData.form.fullScreen
        ? true
        : false;
    }
  }
};
</script>
