import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.store";
import navigation from "./navigation.store";
import htmlClass from "./htmlclass.store";
import config from "./config.store";
import breadcrumbs from "./breadcrumbs.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    navigation,
    htmlClass,
    config,
    breadcrumbs
  }
});
