import Vue from "vue";
import VeeValidate from "vee-validate";
import i18n from "./vue-i18n";
// import { strong_password } from "./customValidations";

//DEFAULT MESSAGES
import(`vee-validate/dist/locale/` + i18n.locale).then(locale => {
  VeeValidate.Validator.localize(i18n.locale, locale);
});

//CUSTOM MESSAGES
// import customValidationMessages from "@/i18n/customValidationMessages";
// Validator.localize(customValidationMessages);

VeeValidate.setMode("passive");

const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 0,
  errorBagName: "validationErrors", // change if property conflicts
  events: "input|blur",
  fieldsBagName: "validationFields",
  // i18n: i18n, // the vue-i18n plugin instance
  // i18nRootKey: "VALIDATIONS", // the nested key under which the validation messages will be located
  locale: i18n.locale,
  inject: true,
  validity: false,
  useConstraintAttrs: true
};

Vue.use(VeeValidate, config);

// Validator.extend("strong_password", strong_password);
require("./customValidations");
export default VeeValidate;
