// EN
/*
    D DEFAULT, SINGULAR
    P PLURAL
    S SHORT
    SP SHORT PLURAL
 */
export const locale = {
  GLOBAL: {
    ABOUT: "About",
    ACTIONS: "Actions",
    ACTIONTYPE: {
      D: "Planting Field Action",
      P: "Planting Field Actions",
      S: "Action Type",
    },
    ACTIONPRODUCT: "The product used in the process",
    ACTIVE: "Active",
    ADD: "Add",
    ADDRESS: "Address",
    AGEOFTREES: "Tree Age",
    ALL: "All",
    MAPTITLE: "Lands",
    TOTALHARVESTS: "Total Harvests",
    HARVESTPRODUCT: "Harvests by Products",
    TOPPRODUCTSYEAR: "Top Products per Year",
    RECENTACTIVITIES: "Recent Activities",
    AMOUNT: "Amount",
    PIECE: "Piece",
    APIUSERS: {
      D: "API User",
      P: "API Users",
    },
    AREA: "Area",
    BASEPRICE: "Base Price",
    BASEPRICES: {
      D: "Base Price",
      P: "Base Prices",
    },
    BIRTHDAY: "Birth day",
    BIO: "Bio",
    BUYERCOMMENTS: {
      D: "Alıcı Yorumu",
      P: "Alıcı Yorumları",
    },
    BUYERS: {
      D: "Buyer",
      P: "Buyers",
    },
    CANCEL: "Cancel",
    CITY: {
      D: "Province",
      P: "Provinces",
    },
    CLICKIMAGESTOUPLOAD: "...or click for install",
    CLOSE: "Close",
    CODE: "Code",
    COMMENT: {
      D: "Comment",
      P: "Comments",
    },
    COMMERCIALINFO: "Commercial Info",
    COMPANY: {
      D: "Company",
      P: "Company",
    },
    COMPANIES: {
      D: "Company",
      P: "Companies",
    },
    COMPANYNAME: "Company Name",
    CONTACT: "Contact",
    CONTACTINFO: "Contact Information",
    CONTENT: "İçerik",
    CONSULTANTCOMMENTS: {
      D: "Danışman Yorumu",
      P: "Danışman Yorumları",
    },
    CONSULTINGPRICE: "Consultant Price",
    CONSULTANTS: {
      D: "Consultant",
      P: "Consultants",
    },
    COORDINATES: "Coordinates",
    CORPORATE: "Corporate",
    COUNTS: "Counts",
    COUNTRY: {
      D: "Country",
      P: "Countrys",
    },
    COUNTY: {
      D: "County",
      P: "Districts",
    },
    CROPLAND: {
      D: "Cropland Area",
      P: "Cropland Areas",
    },
    CROPLANDS: {
      D: "Cropland Area",
      P: "Cropland Areas",
    },
    CROPLANDACTIONS: {
      D: "Cropland Area Action",
      P: "Cropland Area Actions",
    },
    CROPLANDCOMMENTS: {
      D: "Field Comment",
      P: "Field Comments",
    },
    CROPLANDTYPE: {
      D: "Field Type",
      P: "Field Types",
      S: "Area Type",
    },
    DATE: {
      D: "Date",
      P: "Dates",
    },
    DASHBOARD: "Dashboard",
    DECARE: "Decare",
    DEFINITIONS: {
      D: "Defination",
      P: "Definations",
    },
    DEFINITIONTYPES: {
      D: "Defination Type",
      P: "Defination Types",
    },
    DEFINITIONTYPE: "Defination Type",
    DELETE: "Delete",
    DESCRIPTION: {
      D: "Description",
      P: "Descriptions",
    },
    DROPFILESTOUPLOAD: "Drag here to upload files",
    DROPIMAGESTOUPLOAD: "Drag here to upload image",
    DISTRICT: {
      D: "District",
      P: "Districts",
    },
    EDIT: "{name} Edit",
    EMAIL: "E-mail",
    EMAILALLOWED: "E-mail allowed",
    END: "End",
    ENDDATE: "End Date",
    ENDPOINT: {
      D: "Endpoint",
      P: "Endpoints",
    },
    ENDPOINTS: {
      D: "Endpoint",
      P: "Endpointler",
    },
    EOSREPORTS: "EOS Reports",
    EXPORT: "Export",
    FARMER: {
      D: "Farmer",
      P: "Farmers",
    },
    FARMERS: {
      D: "Farmer",
      P: "Farmers",
    },
    FARMERCOMMENTS: {
      D: "Farmer Comment",
      P: "Farmer Comments",
    },
    FILE: "File",
    FILEMANAGER: "File Manager",
    FILTER: {
      D: "Filter",
      P: "Filters",
    },
    FOOTER: "Footer",
    FORGOTPASSWORD: "Forgot Password?",
    FULLNAME: "Name & Surname",
    GET: "View",
    GROUP: {
      D: "Group",
      P: "Groups",
    },
    GREETINGS: "Welcome",
    HARVEST: {
      D: "Harvest",
      P: "Harvests",
    },
    HARVESTCOMMENTS: {
      D: "Harvest Comment",
      P: "Harvest Comments",
    },
    HARVESTS: {
      D: "Harvest",
      P: "Harvests",
    },
    HEADER: "Başlık",
    HELPFUL: "Helpfull",
    ICON: "Icon",
    ID: "ID",
    IDENTIFYNO: "ID Number",
    IMAGE: "Image",
    IMAGEEDITOR: "Image Editor",
    INDIVIDUAL: "Individual",
    INFO: "Informations",
    IPADDRESS: {
      D: "IP Address",
      P: "IP Addresses",
    },
    ITEM_ADD: "New Registration",
    ITEM_EDIT: "Edit",
    ITEM_DELETE: "Delete",
    KEY: "Key",
    LAND: {
      D: "Field",
      P: "Fields",
    },
    LANDCOMMENTS: {
      D: "Field Comment",
      P: "Field Comments",
    },
    LANDS: {
      D: "Field",
      P: "Fields",
    },
    LANDNAME: "Field Name",
    LANDSINFO: "Field Info",
    LANDTYPE: "Field Type",
    LANGUAGE: {
      D: "Language",
      P: "Languages",
    },
    LANGUAGES: {
      D: "Language",
      P: "Languages",
    },
    LIST: "List",
    LOADING: "Loading",
    LOCATION: {
      D: "Location",
      P: "Locations",
    },
    LOCATIONS: {
      D: "Location",
      P: "Locations",
    },
    LOGINBUTTON: "Login",
    LOGISTICS: "Lojistik",
    LOGISTICCOMPANIES: {
      D: "Logistics Company",
      P: "Logistics Companies",
    },
    MODULE: {
      D: "Module",
      P: "Modules",
    },
    MODULES: {
      D: "Module",
      P: "Modules",
    },
    MODULEGROUP: {
      D: "Module Group",
      P: "Module Groups",
    },
    MODULEGROUPS: {
      D: "Module Group",
      P: "Module Groups",
    },
    NAME: "Name",
    NEW: "New {name}",
    NEIGHBORHOOD: "Neighborhood",
    NOOFTREES: "Number of Trees",
    NOTE: "Not",
    NOTIFICATIONS: {
      D: "Bildirim",
      P: "Bildirimler",
    },
    NOTIFICATIONTEMPLATES: {
      D: "Notification Template",
      P: "Notification Templates",
    },
    OFFLINE: "Offline",
    ONLINE: "Online",
    NOCRECORD: "No Record",
    PAGE: "Page",
    PANELSETTINGS: "Panel Settings",
    PACKINGPRICE: "Packing Price",
    PASSIVE: "Pasif",
    PASSWORD: "Password",
    PASSWORDCONFIRM: "Password Confirm",
    PERSONALINFO: "Personal Info",
    PICKINGPRICE: "Picking Price",
    PHONE: "Phone",
    PLEASESELECT: "Please Select",
    PLOT: "Plot",
    PRODUCT: {
      D: "Product",
      P: "Products",
    },
    PRODUCTS: {
      D: "Product",
      P: "Products",
    },
    PRODUCTNAME: "Product Name",
    PRODUCTKIND: {
      D: "Product Type",
      P: "Product Types",
    },
    PRODUCTKINDS: {
      D: "Product Type",
      P: "Product Types",
    },
    PRODUCTPRICES: "Product Prices",
    PROFILEIMAGE: "Profile Image",
    PROFILECOVERIMAGE: "Profile Cover Image",
    PROFILETEXT: "Profile Text",
    POST: "New",
    PUT: "Update",
    RATING: "Rating",
    REFRESH: "Refresh",
    REORDER: "Sort",
    RESET: "Reset",
    ROLE: {
      D: "Role",
      P: "Roles",
    },
    ROLES: {
      D: "Role",
      P: "Roles",
    },
    ROUNDABOUT: "roundabout",
    ROLEMODULES: "Role Modules",
    RTL: "Rtl",
    SAVE: "Save",
    SAVEALL: "Save All",
    SELECTLANGUAGE: "Select Langague",
    SETTINGS: {
      D: "Setting",
      P: "Settings",
    },
    SIGNINMANAGER: "Sign in Manager",
    SIGNIN: "Sign In",
    SIGNOUT: "Sign Out",
    SHEET: "Sheet",
    SHORTCODE: "Short Code",
    SMS: "SMS",
    SMSALLOWED: "SMS Allowed",
    STATUS: "Status",
    START: "Start",
    STARTDATE: "Start Date",
    STATISTICS: "statistics",
    SUBSTATUS: "Sub status",
    TAGS: "Etiketler",
    TARGETSTATUS: "target status",
    TARGETSUBSTATUS: "target sub status",
    TAXNO: "Tax No",
    TAXOFFICE: "Tax Office",
    TEMPLATE: "Şablon",
    TEST: "TEST",
    TITLE: "Title",
    TREEGAP: "Tree Gap",
    TREECOUNT: "Number of Trees",
    TYPE: {
      D: "Type",
      P: "Types",
    },
    UNHELPFUL: "Unhelpfull",
    UNITPRICE: "Product Kg Price",
    UPLOADIMAGE: "Upload Image",
    UPLOAD: "Upload",
    UPLOADTITLE: "Upload File",
    UPLOADSUBTITLE: "Image",
    USERS: {
      D: "User",
      P: "Users",
    },
    WEBSITE: "Web Site",
    VALUE: "Value",
    VISIBLE: "Visible",
    ZIPCODE: "Zip Code",
    MONTHS: {
      JANUARY: "January",
      FEBRUARY: "February",
      MARCH: "March",
      APRIL: "April",
      MAY: "May",
      JUNE: "June",
      JULY: "July",
      AUGUST: "August",
      SEPTEMBER: "September",
      OCTOBER: "October",
      NOVEMBER: "November",
      DECEMBER: "December",
    },
  },
  MESSAGES: {
    ADDED: "Added...",
    CONFIRM: "Are You Sure?",
    DELETECONFIRMTEXT: "This Record will be permanently deleted, are you sure?",
    DELETED: "Deleted...",
    ERROR: "Error",
    FORMHASERRORS: "Form Has Errors",
    INDEXERROR: "Index Error (Index Error)",
    INFO: "Info",
    QUESTION: "Question",
    REQUIREDFIELD: "{fieldname} Required",
    ROUTEERROR: "Endpoint Error",
    SUCCESS: "Succsess",
    UPDATED: "Updated...",
    USERNOTFOUND: "User not Found",
    WARNING: "Warning",
  },
  VALIDATIONS: {
    STRONGPASSWORD:
      "{0} Must contain at least 1 uppercase, 1 lowercase, 1 number and 1 special character.(Ör. , . _ & ? vs..)",
  },
};
