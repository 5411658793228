import Vue from "vue";

import HelperMethods from "./GlobalMixin";

const Mixins = { HelperMethods };

Object.keys(Mixins).forEach(name => {
  Vue.mixin(name, Mixins[name]);
});

export default Mixins;
