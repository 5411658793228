<template>
  <v-dialog fullscreen v-model="show" @keydown.esc="closeForm">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ t("GLOBAL.FILEMANAGER") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="closeForm">
            <v-icon size="small" class="mr-1">cancel</v-icon>
            {{ t("GLOBAL.CLOSE") }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <b-tabs class="mt-3">
          <b-tab v-for="tab in tabs" :key="tab.code" :title="tab.name">
            <b-container fluid>
              <b-row>
                <b-col cols="12">
                  <Upload
                    :code="tab.code"
                    v-model="groupedFiles[tab.code]"
                    :title="t('GLOBAL.UPLOADTITLE')"
                    :subTitle="t('GLOBAL.DROPFILESTOUPLOAD')"
                    @addedFiles="addedFiles($event, tab.code)"
                    @deletedFile="deletedFile($event, tab.code)"
                    :multiple="true"
                    :max-file-size="10"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="closeForm">
          <v-icon size="small" class="mr-1">cancel</v-icon>
          {{ t("GLOBAL.CLOSE") }}
        </v-btn>
        <v-btn color="primary" @click="saveClick">
          <v-icon size="small" class="mr-1">save</v-icon>
          {{ t("GLOBAL.SAVE") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function groupBy(array, key) {
  const result = {};
  array.forEach(item => {
    if (!result[item[key]]) {
      result[item[key]] = [];
    }
    result[item[key]].push(item);
  });
  return result;
}

export default {
  name: "FileManager",
  components: {},
  data() {
    return {
      groupedFiles: {},
      tabs: null
    };
  },
  props: {
    value: Boolean,
    files: Array,
    moduleData: {
      default: {}
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    saveClick() {
      this.$emit("saveFileManagerClicked");
    },
    closeForm() {
      this.$emit("closeFileManagerClicked");
    },
    addedFiles(uploadedFiles, code) {
      this.$emit("addedFiles", uploadedFiles, code);
    },
    deletedFile(link, code) {
      this.$emit("deletedFile", link, code);
    }
  },
  watch: {
    files: {
      handler() {
        this.groupedFiles = groupBy(this.files, "fileGroup");
      },
      deep: true
    }
  },
  created() {
    this.apiGetDefinitionByTypeCode(this.moduleData.fileManager).then(res => {
      this.tabs = res;
    });
  }
};
</script>

<style scoped></style>
