import Vue from "vue";
import VueSelect from "vue-select";
import Editor from "@tinymce/tinymce-vue";
import MaskedInput from "vue-maskedinput";
import DatePicker from "vue2-datepicker";

import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/tr";

const Components = {
  VueSelect,
  Editor,
  MaskedInput,
  DatePicker
};

Object.keys(Components).forEach(name => {
  Vue.component(name, Components[name]);
});

export default Components;
