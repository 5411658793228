var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.maxWidth,"fullscreen":_vm.fullscreen},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeForm($event)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeForm}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._t("title",null,{"item":_vm.item})],2),_c('v-spacer'),_c('v-toolbar-items',[_vm._t("formTopActions",null,{"item":_vm.item}),(
            (_vm.item.hasOwnProperty(_vm.moduleData.primaryKey) && _vm.permissions.put) ||
              (!_vm.item.hasOwnProperty(_vm.moduleData.primaryKey) &&
                _vm.permissions.post)
          )?_c('v-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.$emit('saveItemClick')}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"small"}},[_vm._v("save")]),_vm._v(_vm._s(_vm.t("GLOBAL.SAVE"))+" ")],1):_vm._e()],2)],1),_c('v-card-text',[_vm._t("form",null,{"item":_vm.item})],2),_c('v-card-actions',[_vm._t("formActionsLeft",null,{"item":_vm.item}),_c('v-spacer'),_vm._t("formActions",null,{"item":_vm.item}),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.closeForm}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"small"}},[_vm._v("cancel")]),_vm._v(" "+_vm._s(_vm.t("GLOBAL.CANCEL"))+" ")],1),(
          (_vm.item.hasOwnProperty(_vm.moduleData.primaryKey) && _vm.permissions.put) ||
            (!_vm.item.hasOwnProperty(_vm.moduleData.primaryKey) && _vm.permissions.post)
        )?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('saveItemClick')}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"small"}},[_vm._v("save")]),_vm._v(" "+_vm._s(_vm.t("GLOBAL.SAVE"))+" ")],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }