<template>
  <v-app>
    <Table
      ref="table"
      :module-data="moduleData"
      :permissions="permissions"
      @showNewItemFormClicked="showNewItemForm"
      @showEditItemFormClicked="showEditItemForm"
      @deleteItemClicked="deleteItem"
      @multipleActionClicked="multipleAction"
      :hide-filters="hideFilters"
    >
      <template v-slot:tableFilters="{ filters }">
        <slot name="tableFilters" v-bind:filters="filters"></slot>
      </template>
      <template v-slot:tableActions>
        <slot name="tableActions"></slot>
      </template>
      <template v-slot:tableColActions v-bind:tableColActions="item">
        <slot name="tableColActions" v-bind:tableColActions="item"></slot>
      </template>
      <template v-slot:tableRowActions="{ rowItem }">
        <slot name="tableRowActions" v-bind:rowItem="rowItem"></slot>
      </template>
      <template
        v-slot:[slot]="{ rowItem }"
        v-for="slot in moduleData.table.customColumns"
      >
        <slot :name="'customColumn_' + slot" v-bind:rowItem="rowItem"></slot>
      </template>
    </Table>
    <FileManager
      ref="fileManager"
      v-if="item && moduleData.fileManager"
      v-model="showFileManager"
      :module-data="moduleData"
      :files="item.files"
      :permissions="permissions"
      @closeFileManagerClicked="closeFileManagerClicked"
      @saveFileManagerClicked="saveFileManagerClicked"
      @addedFiles="addedFiles"
      @deletedFile="deletedFile"
    ></FileManager>
    <Form
      ref="form"
      v-if="item"
      v-model="showForm"
      :module-data="moduleData"
      :item="item"
      :permissions="permissions"
      @saveItemClick="saveItem"
      @close="saveItem"
      @closeItemEditFormClicked="closeItemEditForm"
    >
      <template v-slot:title v-bind:item="item">
        {{
          item[moduleData.primaryKey]
            ? t("GLOBAL.EDIT", {
                name: t("GLOBAL." + moduleData.name.toUpperCase())
              }) +
              " (" +
              item[moduleData.primaryKey] +
              ")"
            : t("GLOBAL.NEW", {
                name: t("GLOBAL." + moduleData.name.toUpperCase())
              })
        }}
      </template>
      <template v-slot:formTopActions v-bind:item="item">
        <slot name="formTopActions" v-bind:item="item"></slot>
        <v-btn
          v-if="item.id > 0 && moduleData.fileManager"
          dark
          color="error"
          class="mr-1"
          @click="showFileManagerDialog(item)"
        >
          <v-icon x-small dark class="mr-1">
            mdi-file
          </v-icon>
          {{ t("GLOBAL.FILEMANAGER") }}
        </v-btn>
      </template>
      <template v-slot:form v-bind:item="item">
        <slot name="form" v-bind:item="item"></slot>
      </template>
      <template v-slot:formActionsLeft v-bind:item="item">
        <slot name="formActionsLeft" v-bind:item="item"></slot>
      </template>

      <template v-slot:formActions v-bind:item="item">
        <slot name="formActions" v-bind:item="item"></slot>
      </template>
    </Form>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.store";
import Table from "./Table";
import Form from "./Form";
import FileManager from "@/components/elements/FileManager";
import JwtService from "@/services/jwt.service";
import jwt from "jsonwebtoken";

export default {
  name: "moduleComponent",
  inject: ["$validator"],
  components: {
    Table,
    Form,
    FileManager
  },
  props: {
    moduleData: {
      type: Object
    }
  },
  data() {
    return {
      defaultItem: null,
      item: null,
      tableSelectedItems: [],
      showForm: false,
      showFileManager: false,
      showComments: false
    };
  },
  methods: {
    showFileManagerDialog() {
      this.saveItem(false, false).then(() => {
        this.showFileManager = true;
      });
    },
    closeFileManagerClicked() {
      this.showFileManager = false;
    },
    saveFileManagerClicked() {
      this.saveItem(false).then(() => {
        this.showFileManager = false;
      });
    },
    addedFiles(files, code) {
      for (let i = 0; i < files.length; i++) {
        files[i]["objectId"] = this.item.id;
        files[i]["fileGroup"] = code;
        files[i]["orderNo"] = this.item.files.length + 1;
        this.item.files.push(files[i]);
      }
    },
    deletedFile(link) {
      let files = [];
      for (let i = 0; i < this.item.files.length; i++) {
        if (link != this.item.files[i].link) {
          files.push(this.item.files[i]);
        }
      }
      this.item.files = files;
      this.saveItem(false, true);
    },
    showNewItemForm() {
      let vm = this;
      this.apiSend("get", `${this.moduleData.name}/empty`).then(item => {
        vm.defaultItem = item;
        if (vm.permissions.post) {
          vm.resetItem();
          vm.$emit("beforeItemLoad", this.item);
          vm.showForm = true;
        } else {
          vm.popAlert(this.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
        }
      });
    },
    loadEditItem(id) {
      this.mylog(new Date(), "edit request", id);
      if (this.permissions.put) {
        this.apiSend("get", `${this.moduleData.name}/${id}`)
          .then(item => {
            this.mylog(new Date(), "edit response", id);
            this.item = item;
            this.$emit("beforeItemLoad", this.item);
          })
          .catch(() => {});
      } else {
        this.popAlert(this.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
      }
    },
    showEditItemForm(id) {
      this.mylog(new Date(), "edit form request", id);
      if (this.permissions.put) {
        this.apiSend("get", `${this.moduleData.name}/${id}`)
          .then(item => {
            this.mylog(new Date(), "edit response", id);
            this.item = item;
            this.$emit("beforeItemLoad", this.item);
            this.showForm = true;
          })
          .catch(() => {});
      } else {
        this.popAlert(this.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
      }
    },
    closeItemEditForm() {
      this.showForm = false;
    },
    saveItem(close, onSuccessFeedback) {
      let that = this;
      return new Promise(function(resolve, reject) {
        that.$validator.validateAll().then(valid => {
          if (
            (typeof onSuccessFeedback == "undefined" && !valid) ||
            (onSuccessFeedback == true && !valid)
          ) {
            let errors = '<ul class="list-group text-left v-application">';
            that.validationErrors.all().forEach(function(error) {
              errors +=
                '<li class="list-group-item">' +
                '<i class="fa fa-times mr-2 red--text"></i>' +
                error +
                " </li>";
            });
            errors += "</ul>";
            that.popAlert(
              errors,
              "error",
              that.$t("MESSAGES.FORMHASERRORS"),
              50000
            );
            reject();
          } else {
            that.$emit("beforeSaveItem", that.item);
            if (
              (typeof that.item[that.moduleData.primaryKey] == "undefined" &&
                that.permissions.post) ||
              (typeof that.item[that.moduleData.primaryKey] != "undefined" &&
                that.permissions.put)
            ) {
              let id = that.item[that.moduleData.primaryKey]
                ? that.item[that.moduleData.primaryKey]
                : 0;
              that
                .apiSend(
                  id == 0 ? "post" : "put",
                  that.moduleData.name + (id == 0 ? "" : `/${id}`),
                  // that.cloneSendItem(that.item)
                  that.item
                )
                .then(() => {
                  if (
                    typeof onSuccessFeedback == "undefined" ||
                    onSuccessFeedback == true
                  ) {
                    that.popAlert(
                      that.$t(id == 0 ? "MESSAGES.ADDED" : "MESSAGES.UPDATED"),
                      "success"
                    );
                  }
                  if (typeof close == "undefined" || close == true) {
                    that.refreshTable();
                    that.closeItemEditForm();
                  }
                  resolve(that.item);
                })
                .catch(() => {
                  reject();
                });
            } else {
              that.popAlert(that.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
              reject();
            }
          }
        });
      }).catch(() => {});
    },
    deleteItem(id) {
      if (this.permissions.delete) {
        this.popConfirm(
          this.$t("MESSAGES.CONFIRM"),
          this.$t("MESSAGES.DELETECONFIRMTEXT")
        ).then(() => {
          this.apiSend("delete", `${this.moduleData.name}/${id}`)
            .then(() => {
              this.popAlert(this.$t("MESSAGES.DELETED"), "success");
              this.refreshTable();
            })
            .catch(() => {});
        });
      } else {
        this.popAlert(this.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
      }
    },
    multipleAction(action, ids) {
      if (action == "delete" && ids.length > 0) {
        if (this.permissions.delete) {
          this.popConfirm(
            this.$t("MESSAGES.CONFIRM"),
            this.$t("MESSAGES.DELETECONFIRMTEXT")
          ).then(() => {
            let p = [];
            let that = this;
            ids.forEach(function(id) {
              p.push(
                that
                  .apiSend("delete", `${that.moduleData.name}/${id}`)
                  .catch(() => {})
              );
            });
            Promise.all(p).then(() => {
              this.popAlert(this.$t("MESSAGES.DELETED"), "success");
              this.refreshTable();
            });
          });
        } else {
          this.popAlert(this.$t("MESSAGES.UNAUTHORIZED_ACTION"), "error");
        }
      }
    },
    resetItem() {
      this.item = Object.assign({}, this.defaultItem);
      this.$emit("changed", this.item);
    },
    refreshTable() {
      this.$refs.table.refreshTable();
    }
  },
  computed: {
    permissions() {
      let token = JwtService.getToken();
      let userData = jwt.decode(token);
      let roleModules = userData.role.roleModules;
      let permissions = {
        post: false,
        get: false,
        put: false,
        delete: false
      };
      roleModules.forEach(item => {
        if (item.module.endPoint == this.moduleData.name) {
          permissions = {
            post: item.post,
            get: item.get,
            put: item.put,
            delete: item.delete
          };
        }
      });
      return permissions;
    },
    slotNames() {
      let arr = [];
      this.moduleData.table.slots.forEach(p => {
        arr.push("item." + p);
      });
      return arr;
    }
  },
  created() {
    this.mylog(new Date(), "module component created");
    if (!this.defaultItem) {
      let vm = this;
      this.apiSend("get", `${this.moduleData.name}/empty`).then(item => {
        vm.defaultItem = item;
        this.resetItem();
      });
    } else {
      this.resetItem();
    }

    if (this.$route.params.id) this.showEditItemForm(this.$route.params.id);
  },
  mounted() {
    if (!this.noBreadCrumb)
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.t("GLOBAL." + this.moduleData.name.toUpperCase() + ".P")
        }
      ]);
  },
  watch: {
    item(val) {
      this.$emit("changed", val);
    },
    showForm(val) {
      if (!val) {
        this.resetItem();
      }
    }
  },
  beforeDestroy() {}
};
</script>
