<template>
  <v-dialog
    name="dialog"
    :max-width="maxWidth"
    :fullscreen="fullscreen"
    v-model="show"
    @keydown.esc="closeForm"
    :eager="eager"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <slot name="content"></slot>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" @click="closeForm">
          <v-icon size="small" class="mr-1">cancel</v-icon>
          {{ t("GLOBAL.CANCEL") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Dlg",
  props: {
    value: Boolean,
    eager: {
      default: false
    },
    fullscreen: {
      default: false
    },
    maxWidth: {
      default: "auto"
    }
  },
  methods: {
    closeForm() {
      this.show = false;
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped></style>
