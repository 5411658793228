import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";

axios.defaults.withCredentials = true;

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    axios.defaults.baseURL = process.env.VUE_APP_APIGATEWAY_URL;
    Vue.use(VueAxios, axios);
  },
  /**
   * Set Apigateway token
   */
  setToken() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Token ${JwtService.getToken()}`;
  },
  async send(method, url, data) {
    return await Vue.axios({
      method: method,
      url: url,
      data: data,
      timeout: 10000 //5sn
    }).catch(error => {
      let err;
      if (error.response) {
        if (error.response.data) {
          err = error.response.data;
        } else {
          //alert("apiservice send unhandled errorDELETE");
          err = {
            errorCode: 501,
            message: `apiservice send unhandled error ${method} ${url}`,
            success: false,
            internalMessage: "",
            data: null
          };
        }
      } else {
        if (error.message === "Network Error") {
          err = {
            errorCode: 501,
            message: `ApiGateway Unavailable ${method} ${url}`,
            success: false,
            internalMessage: "",
            data: null
          };
        } else {
          //alert("apiservice send unhandled error2");
          err = {
            errorCode: 501,
            message: `apiservice send unhandled error2 ${method} ${url}`,
            success: false,
            internalMessage: "",
            data: null
          };
        }
      }
      throw err; //new Error(`[KT] ApiService ${url} ${errorDELETE.response.data}`);
    });
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${resource} ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  async get(resource, slug = "") {
    return await Vue.axios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] ApiService ${resource}/${slug} ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params).catch(error => {
      throw new Error(`[KT] ApiService ${resource} ${error}`);
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params).catch(error => {
      throw new Error(`[KT] ApiService ${resource}/${slug} ${error}`);
    });
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params).catch(error => {
      throw new Error(`[KT] ApiService ${resource} ${error}`);
    });
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${resource} ${error}`);
    });
  }
};

export default ApiService;
