import { Validator } from "vee-validate";
import i18n from "./vue-i18n";

const strong_password = {
  getMessage() {
    return i18n.t("VALIDATIONS.STRONGPASSWORD", [i18n.t("GLOBAL.PASSWORD")]);
  },
  validate(value) {
    let strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    return strongRegex.test(value);
  }
};

Validator.extend("strong_password", strong_password);
