import Vue from "vue";
import ModuleComponent from "@/components/module/ModuleComponent";
import Upload from "@/components/elements/Upload";
import VSelect2 from "@/components/elements/VSelect2";
import Map from "@/components/elements/Map";
import IntlTel from "@/components/elements/IntlTel";
import TableStatusColumn from "@/components/elements/TableStatusColumn";
import DecimalInput from "@/components/elements/DecimalInput";
import TinyEditor from "@/components/elements/TinyEditor";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import BDatePicker from "@/components/elements/BDatePicker";
import Dlg from "@/components/elements/Dlg";

const CustomComponents = {
  ModuleComponent,
  Upload,
  VSelect2,
  Map,
  IntlTel,
  TableStatusColumn,
  DecimalInput,
  TinyEditor,
  KTPortlet,
  BDatePicker,
  Dlg
};

Object.keys(CustomComponents).forEach(name => {
  Vue.component(name, CustomComponents[name]);
});

export default CustomComponents;
