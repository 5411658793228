<template>
  <div>
    <KTPortlet
      v-bind:title="t('GLOBAL.FILTER')"
      class="form-filters"
      v-if="moduleData.table.filters"
      :hidden="hideFilters"
    >
      <template v-slot:body>
        <slot name="tableFilters" v-bind:filters="filters"></slot>
      </template>
      <template v-slot:foot>
        <div class="float-right">
          <v-btn
            color="blue-grey"
            small
            dark
            class="mr-1"
            @click="resetTable()"
            v-if="moduleData.table.filters"
            ><v-icon x-small dark>mdi-close</v-icon
            >{{ $t("GLOBAL.RESET") }}</v-btn
          >
          <v-btn color="blue-grey darken-4" small dark @click="refreshTable()"
            ><v-icon x-small dark>mdi-autorenew</v-icon
            >{{ $t("GLOBAL.REFRESH") }}</v-btn
          >
        </div>
      </template>
    </KTPortlet>
    <!--		{{ item[moduleData.primaryKey] ? t("GLOBAL.EDIT", {name: t("GLOBAL."+moduleData.name.toUpperCase())}) : t("GLOBAL.NEW", {name: t("GLOBAL."+moduleData.name.toUpperCase())})}}-->
    <KTPortlet
      v-bind:title="t('GLOBAL.' + moduleData.name.toUpperCase() + '.P')"
      class="form-table"
    >
      <template v-slot:toolbar>
        <slot name="tableActions">
          <vue-excel-xlsx
            v-if="tableSelectedItems"
            :data="xlsData"
            :columns="xlsColumns"
            :filename="moduleData.name"
            :sheetname="moduleData.name"
            class="mr-2"
            ><v-btn small color="primary"
              ><v-icon medium dark>mdi-microsoft-excel</v-icon
              >{{ t("GLOBAL.EXPORT") }}</v-btn
            ></vue-excel-xlsx
          >
        </slot>
        <v-btn
          color="blue"
          small
          dark
          @click="$emit('showNewItemFormClicked')"
          v-if="permissions.post"
          ><v-icon small dark>mdi-plus</v-icon>{{ t("GLOBAL.ITEM_ADD") }}</v-btn
        >
        <v-btn
          v-if="tableSelectedItems.length > 0 && permissions.delete"
          small
          color="pink"
          dark
          class="ml-2"
          @click="deleteItemsClick(tableSelectedItems)"
          ><v-icon small dark>mdi-trash-can</v-icon
          >{{ t("GLOBAL.ITEM_DELETE") }}</v-btn
        >
      </template>
      <template v-slot:body>
        <v-data-table
          ref="vtable"
          v-if="tableItems"
          :items="tableItems"
          v-model="tableSelectedItems"
          :headers="headers"
          :sort-by="moduleData.table.sortBy"
          :item-key="moduleData.primaryKey"
          :page="page"
          @update:options="refreshTable"
          :items-per-page="itemsPerPage"
          show-select
          :server-items-length="serverItemsLength"
          :loading="loading"
          :loading-text="loadingText"
          :footer-props="footerProps"
          @click:row="rowClick"
        >
          <template v-slot:top></template>
          <template v-slot:item.action="{ item }">
            <slot name="tableRowActions" v-bind:rowItem="item"></slot>
            <div>
              <v-btn
                color="blue"
                class="mr-1"
                dark
                x-small
                @click="
                  $emit('showEditItemFormClicked', item[moduleData.primaryKey])
                "
                v-if="permissions.get"
              >
                <v-icon x-small dark>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                color="pink"
                x-small
                dark
                @click="$emit('deleteItemClicked', item[moduleData.primaryKey])"
                v-if="permissions.delete"
              >
                <v-icon x-small dark>
                  mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:[slot]="{ item }" v-for="slot in customColumns">
            <slot
              :name="slot.replace('item.', '')"
              v-bind:rowItem="item"
            ></slot>
          </template>
        </v-data-table>
      </template>
    </KTPortlet>
  </div>
</template>
<script>
export default {
  name: "Table",
  props: {
    moduleData: {
      default: {}
    },
    permissions: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      footerProps: {
        showFirstLastPage: true,
        // firstIcon: 'mdi-arrow-collapse-left',
        // lastIcon: 'mdi-arrow-collapse-right',
        // prevIcon: 'mdi-minus',
        // nextIcon: 'mdi-plus',
        itemsPerPageText: this.t("GLOBAL.PAGE"),
        itemsPerPageAllText: this.t("GLOBAL.ALL"),
        itemsPerPageOptions: [10, 50, 100, -1]
      },
      loadingText: this.t("GLOBAL.LOADING"),
      loading: true,
      dataLoading: false,
      page: 1,
      itemsPerPage: 10,
      serverItemsLength: 0,
      tableItems: [],
      dataTableClickCount: 0,
      clickTimer: null,
      tableSelectedItems: [],
      filterTimeout: null,
      sort: ""
    };
  },
  computed: {
    headers() {
      let headers = this.moduleData.table.headers;
      if (headers.filter(p => p.value == "action").length == 0)
        headers.push({
          text: this.t("GLOBAL.ACTIONS"),
          value: "action",
          align: "center",
          sortable: false,
          width: "90"
        });
      return headers;
    },
    xlsData() {
      let headers = this.moduleData.table.headers;
      let xlsData = this.tableItems.map(p => {
        let row = {};
        headers.forEach(r => {
          if (p.value != "action")
            row[r.value] = this.objectPropertyValueByString(p, r.value);
        });
        return row;
      });
      return xlsData;
    },
    xlsColumns() {
      let columns = [];
      let headers = this.moduleData.table.headers;
      headers.forEach(p => {
        if (p.value != "action")
          columns.push({
            label: p.text,
            field: p.value
          });
      });
      return columns;
    },
    customColumns() {
      let arr = [];
      if (this.moduleData.table.hasOwnProperty("customColumns")) {
        this.moduleData.table.customColumns.forEach(p => {
          arr.push("item." + p);
        });
      }
      return arr;
    },
    filters() {
      return this.moduleData.table.filters;
    }
  },
  methods: {
    refreshTable(o) {
      this.mylog(new Date(), "refresh table", o);

      if (typeof o != "undefined") {
        /*
                    o : {
                        page: number
                        itemsPerPage: number
                        sortBy: string[]
                        sortDesc: boolean[]
                        groupBy: string[]
                        groupDesc: boolean[]
                        multiSort: boolean
                        mustSort: boolean
                      }
                    * */
        if (Array.isArray(o.sortBy)) {
          if (o.sortBy.length > 0) {
            this.sort =
              o.sortBy[0] +
              (o.sortDesc.length > 0 ? (o.sortDesc[0] ? " DESC" : " ASC") : "");
          } else {
            this.sort = this.moduleData.table.sortBy;
          }
        } else {
          this.sort = o.sortBy;
        }
        this.page = o.page;
        this.itemsPerPage = o.itemsPerPage;
      } else {
        if (typeof this.sort === undefined)
          this.sort = this.moduleData.table.sortBy;
      }

      this.createTable(this.page, this.itemsPerPage, this.sort, this.filters);
    },
    resetTable() {
      for (let key in this.filters) {
        this.filters[key].value = null;
      }
      this.refreshTable();
    },
    createTable(page, rowCount, sort, filters) {
      this.mylog(new Date(), " table request");
      this.apiSendGraph(
        this.moduleData.name,
        this.moduleData.table.graphqlQuery,
        sort,
        filters,
        page,
        rowCount
      ).then(data => {
        this.mylog(new Date(), " table response");
        this.tableSelectedItems = [];
        this.tableItems = data.data;
        this.serverItemsLength = data.count;
        this.loading = false;
      });
    },
    deleteItemsClick(selectedItems) {
      if (selectedItems.length > 0) {
        let ids = [];
        for (let i = 0; i < selectedItems.length; i++) {
          ids.push(selectedItems[i][this.moduleData.primaryKey]);
        }
        this.$emit("multipleActionClicked", "delete", ids);
      }
    },
    rowClick(rowItem) {
      let vm = this;
      this.dataTableClickCount++;
      if (this.dataTableClickCount == 1) {
        this.clickTimer = setTimeout(function() {
          vm.dataTableClickCount = 0;
        }, 250);
      } else if (this.dataTableClickCount == 2) {
        clearTimeout(this.clickTimer);
        vm.dataTableClickCount = 0;
        vm.$emit("showEditItemFormClicked", rowItem.id);
      }
    }
  },
  created() {
    this.mylog(new Date(), "table created");
  },
  watch: {
    filters: {
      handler() {
        let vm = this;
        clearTimeout(this.filterTimeout);
        this.mylog(new Date(), "watch filters");
        this.filterTimeout = setTimeout(() => vm.refreshTable(), 500);
      },
      deep: true
    }
  }
};
</script>
