<template>
  <v-btn color="success" dark x-small v-if="value">
    <v-icon x-small dark>
      mdi-check
    </v-icon>
  </v-btn>
  <v-btn color="error" dark x-small v-else>
    <v-icon x-small dark>
      mdi-cancel
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "TableStatusColumn",
  props: {
    value: {
      type: Boolean
    }
  }
};
</script>

<style scoped></style>
