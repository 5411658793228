// RU
/*
    D DEFAULT, SINGULAR
    P PLURAL
    S SHORT
    SP SHORT PLURAL
 */
export const locale = {
  GLOBAL: {
    ABOUT: "Описание",
    ACTIONS: "Действие",
    ACTIONTYPE: {
      D: "Добавить действие в поле",
      P: "Добавить действия в поле",
      S: "Вид Действий"
    },
    ACTIONPRODUCT: "Используемый в процессе продукт",
    ACTIVE: "Активный",
    ADD: "Добавить",
    ADDRESS: "Адрес",
    AGEOFTREES: "Возраст Деревьев",
    ALL: "Все",
    AMOUNT: "Количество",
    APIUSERS: {
      D: "API Пользователь",
      P: "API Пользователи"
    },
    AREA: "Площадь",
    BASEPRICE: "Базовая Цена",
    BASEPRICES: {
      D: "Базовая Цена",
      P: "Базовые Це"
    },
    BIRTHDAY: "Дата Рождения",
    BIO: "Bio",
    BUYERCOMMENTS: {
      D: "Alıcı Yorumu",
      P: "Alıcı Yorumları"
    },
    BUYERS: {
      D: "Покупатель",
      P: "Покупатели"
    },
    CANCEL: "Отменить",
    CITY: {
      D: "Город",
      P: "Города"
    },
    CLICKIMAGESTOUPLOAD: "...Нажать для загрузки",
    CLOSE: "Закрыть",
    CODE: "Код",
    COMMENT: {
      D: "Отзыв",
      P: "Отзывы"
    },
    COMMERCIALINFO: "Коммерческая Информация",
    COMPANY: {
      D: "Компания",
      P: "Компании"
    },
    COMPANIES: {
      D: "Компания",
      P: "Компании"
    },
    COMPANYNAME: "Название Компании",
    CONTACT: "Контакты",
    CONTACTINFO: "Контактная Информация",
    CONTENT: "İçerik",
    CONSULTANTCOMMENTS: {
      D: "Danışman Yorumu",
      P: "Danışman Yorumları"
    },
    CONSULTINGPRICE: "Цена Консультации",
    CONSULTANTS: {
      D: "Консультант",
      P: "Консультанты"
    },
    COORDINATES: "Координаты",
    CORPORATE: "Корпоративный",
    COUNTS: "Counts",
    COUNTRY: {
      D: "Страна",
      P: "Страны"
    },
    COUNTY: {
      D: "Страна",
      P: "Районы"
    },
    CROPLAND: {
      D: "Посевная Площадь",
      P: "Посевные Площади"
    },
    CROPLANDS: {
      D: "Посевная Площадь",
      P: "Посевные Площади"
    },
    CROPLANDACTIONS: {
      D: "Действие в Посевной Площади ",
      P: "Действия Посевных Площадей"
    },
    CROPLANDCOMMENTS: {
      D: "Отзыв о Поле",
      P: "Отзывы о Поле"
    },
    CROPLANDTYPE: {
      D: "Вид  Поля",
      P: "Вид Полей",
      S: "Размер Площади"
    },
    DATE: {
      D: "Дата",
      P: "Даты"
    },
    DASHBOARD: "Панель Управления",
    DECARE: "Размер Земли по 10 соток",
    DEFINITIONS: {
      D: "Определение",
      P: "Определения"
    },
    DEFINITIONTYPES: {
      D: "Тип Определения",
      P: "Типы Определения"
    },
    DEFINITIONTYPE: "Тип Определения",
    DELETE: "Удалить",
    DESCRIPTION: {
      D: "Описание",
      P: "Описания"
    },
    DROPFILESTOUPLOAD: "Добавить Файл",
    DROPIMAGESTOUPLOAD: "Добавить Фотографию",
    DISTRICT: {
      D: "Район",
      P: "Районы"
    },
    EDIT: "{name} Изменить",
    EMAIL: "E-mail",
    EMAILALLOWED: "E-mail Подтвержденный",
    END: "Окончание",
    ENDDATE: "Дата Окончания",
    ENDPOINT: {
      D: "Конечная Точка",
      P: "Конечные Точки"
    },
    ENDPOINTS: {
      D: "Конечная Точка",
      P: "Конечные Точки"
    },
    EOSREPORTS: "EOS Reports",
    EXPORT: "Export",
    FARMER: {
      D: "Фермер",
      P: "Фермеры"
    },
    FARMERS: {
      D: "Фермер",
      P: "Фермеры"
    },
    FARMERCOMMENTS: {
      D: "Отзыв Фермера",
      P: "Отзывы Фермера"
    },
    FILE: "Файл",
    FILEMANAGER: "Файловый Менеджер",
    FILTER: {
      D: "Фильтр",
      P: "Фильтры"
    },
    FOOTER: "Footer",
    FORGOTPASSWORD: "Забыл Пароль?",
    FULLNAME: "Имя и Фамилия",
    GET: "Обзор",
    GROUP: {
      D: "Группа",
      P: "Группы"
    },
    GREETINGS: "Добро Пожаловать",
    HARVEST: {
      D: "Уборка Урожая",
      P: "Уборка Урожая"
    },
    HARVESTCOMMENTS: {
      D: "Комментарии Урожая",
      P: "Комментарии Урожая"
    },
    HARVESTS: {
      D: "Уборка Урожая",
      P: "Уборка Урожая"
    },
    HEADER: "Başlık",
    HELPFUL: "Полезно",
    ICON: "Значок",
    ID: "ID",
    IDENTIFYNO: "ID Номер",
    IMAGE: "Изображение",
    IMAGEEDITOR: "Редактировать Изображение",
    INDIVIDUAL: "Физическое Лицо",
    INFO: "Информация",
    IPADDRESS: {
      D: "IP Адрес",
      P: "IP Адрес"
    },
    ITEM_ADD: "Новая Регистрация",
    ITEM_EDIT: "Редактировать",
    ITEM_DELETE: "Удалить",
    KEY: "Ключь",
    LAND: {
      D: "Земельный Участок",
      P: "Земельные Участки"
    },
    LANDCOMMENTS: {
      D: "Земельный комментарий",
      P: "Земельный Комментарии"
    },
    LANDS: {
      D: "Земельный Участок",
      P: "Земельные Участки"
    },
    LANDNAME: "Имя Участка",
    LANDSINFO: "Информация Участка",
    LANDTYPE: "Вид Участка",
    LANGUAGE: {
      D: "Язык",
      P: "Языки"
    },
    LANGUAGES: {
      D: "Язык",
      P: "Языки"
    },
    LIST: "Список",
    LOADING: "Загрузка",
    LOCATION: {
      D: "Расположение",
      P: "Расположение"
    },
    LOCATIONS: {
      D: "Расположение",
      P: "Расположение"
    },
    LOGINBUTTON: "Вход",
    LOGISTICCOMPANYCOMMENTS: {
      D: "Lojistik Firması Yorumu",
      P: "Lojistik Firması Yorumları"
    },
    LOGISTICS: "Lojistik",
    LOGISTICCOMPANIES: {
      D: "Логистическая Компания",
      P: "Логистические Компании"
    },
    MODULE: {
      D: "Модуль",
      P: "Mодули"
    },
    MODULES: {
      D: "Модуль",
      P: "Модули"
    },
    MODULEGROUP: {
      D: "Группа Модулей",
      P: "Модульные Группы"
    },
    MODULEGROUPS: {
      D: "Группа Модулей",
      P: "Модульные Группы"
    },
    NAME: "Имя",
    NEW: "Новое {namе}",
    NEIGHBORHOOD: "Окрестность",
    NOOFTREES: "Количество Деревьев",
    NOTE: "Not",
    NOTIFICATIONS: {
      D: "Bildirim",
      P: "Bildirimler"
    },
    NOTIFICATIONTEMPLATES: {
      D: "Notification Template",
      P: "Notification Templates"
    },
    OFFLINE: "Offline",
    ONLINE: "Online",
    NOCRECORD: "Нет Записи",
    PAGE: "Страница",
    PANELSETTINGS: "Настройки Панели",
    PACKINGPRICE: "Цена Упаковки",
    PASSIVE: "Pasif",
    PASSWORD: "Пароль",
    PASSWORDCONFIRM: "Подтвердить Пароль",
    PERSONALINFO: "Личные Данные",
    PICKINGPRICE: "Цена Сбора",
    PHONE: "Телефон",
    PLEASESELECT: "Пожалуста выберете",
    PLOT: "Часть Участка",
    PRODUCT: {
      D: "Продукт",
      P: "Продукты"
    },
    PRODUCTS: {
      D: "Продукты",
      P: "Продукты"
    },
    PRODUCTNAME: "Имя Продукта",
    PRODUCTKIND: {
      D: "Тип Продукта",
      P: "Типы Продуктов"
    },
    PRODUCTKINDS: {
      D: "Тип Продукта",
      P: "Типы Продуктов"
    },
    PRODUCTPRICES: "Цена Продуктов",
    PROFILEIMAGE: "Изображение Профиля",
    PROFILECOVERIMAGE: "Изображение Обложки Профиля",
    PROFILETEXT: "Текст Профиля",
    POST: "Добавить Пост",
    PUT: "Обновить",
    RATING: "Рейтинг",
    REFRESH: "Обновление",
    REORDER: "Заказать Заново",
    RESET: "Сброс",
    ROLE: {
      D: "Роль",
      P: "Роли"
    },
    ROLES: {
      D: "Роль",
      P: "Роли"
    },
    ROUNDABOUT: "Круговое Движение",
    ROLEMODULES: "Модульные Роли",
    RTL: "Rtl",
    SAVE: "Сохранить",
    SAVEALL: "Сохранить Все",
    SELECTLANGUAGE: "Выбрать Язык",
    SETTINGS: {
      D: "Настройки",
      P: "Настройки"
    },
    SIGNINMANAGER: "Менеджер Входа",
    SIGNIN: "Войти в Систему",
    SIGNOUT: "Выход из Системы",
    SHEET: "Лист",
    SHORTCODE: "Короткий Код",
    SMS: "SMS",
    SMSALLOWED: "Разрешить SMS",
    STATUS: "Статус",
    START: "Начать",
    STARTDATE: "Дата Начала",
    STATISTICS: "statistics",
    SUBSTATUS: "Sub status",
    TAGS: "tags",
    TARGETSTATUS: "target status",
    TARGETSUBSTATUS: "target sub status",
    TAXNO: "ИНН",
    TAXOFFICE: "Налоговая Инспекия",
    TEMPLATE: "Şablon",
    TEST: "Проверить",
    TITLE: "Титл",
    TREEGAP: "Промежуток Между Деревьями",
    TREECOUNT: "Количество Деревьев",
    TYPE: {
      D: "Тип",
      P: "Типы"
    },
    UNHELPFUL: "Бесполезно",
    UNITPRICE: "Стоимость Продукта за кг",
    UPLOADIMAGE: "Добавить Изображение",
    UPLOAD: "Загрузить",
    UPLOADTITLE: "Статус Загрузки",
    UPLOADSUBTITLE: "Изображение",
    USERS: {
      D: "Пользователь",
      P: "Пользователи"
    },
    WEBSITE: "Веь-Сайт",
    VALUE: "Ценность",
    VISIBLE: "Visible",
    ZIPCODE: "Индекс",
    MONTHS: {
      JANUARY: "Январь",
      FEBRUARY: "Февраль",
      MARCH: "Март",
      APRIL: "Апрель",
      MAY: "Май",
      JUNE: "Июнь",
      JULY: "Июль",
      AUGUST: "Август",
      SEPTEMBER: "Сентябрь",
      OCTOBER: "Октябрь",
      NOVEMBER: "Ноябрь",
      DECEMBER: "Декабрь"
    }
  },
  MESSAGES: {
    ADDED: "Добавлено...",
    CONFIRM: "Вы Уверены?",
    DELETECONFIRMTEXT: "Эта форма удалится навсегда, вы уверены?",
    DELETED: "Deleted...",
    ERROR: "Ощибка",
    FORMHASERRORS: "Форма Имеет Ошибки",
    INDEXERROR: "Ошибка индекса (Index Error)",
    INFO: "Информация",
    QUESTION: "Вопрос",
    REQUIREDFIELD: "{Имя Поля} Обязательно",
    ROUTEERROR: "Ошибка конечных Точек",
    SUCCESS: "Успешно",
    UPDATED: "Обновлено...",
    USERNOTFOUND: "Пользователь не найден",
    WARNING: "Предупреждение"
  },
  VALIDATIONS: {
    STRONGPASSWORD:
      "{0} Пароль должен содержать хотя бы 1 верхний регистр, 1 нижний, 1 номерной знак и 1 особый значок.(Or. , . _ & ? vs..)"
  }
};
