<template>
  <b-form-datepicker
    :id="id"
    v-model="dateValue"
    v-bind="labels[$i18n.locale] || {}"
    @input="$emit('input', $event)"
    :placeholder="placeholder"
    :hide-header="hideHeader"
    :show-decade-nav="showDecadeNav"
    :locale="$i18n.locale"
  >
  </b-form-datepicker>
</template>

<script>
export default {
  name: "BDatePicker",
  props: {
    value: String,
    placeholder: String,
    id: String,
    hideHeader: Boolean,
    showDecadeNav: Boolean
  },
  data() {
    return {
      dateValue: new Date(),
      labels: {
        tr: {
          labelPrevDecade: "Önceki On Yıl",
          labelPrevYear: "Önceki Yıl",
          labelPrevMonth: "Önceki Ay",
          labelCurrentMonth: "Bu Ay",
          labelNextMonth: "Gelecek Ay",
          labelNextYear: "Gelecek Yıl",
          labelNextDecade: "Gelecek On Yıl",
          labelToday: "Bugün",
          labelSelected: "Seçilen Tarih",
          labelNoDateSelected: "Tarih Seçilmedi",
          labelCalendar: "Takvim",
          labelNav: "Takvimde Gezinme",
          labelHelp: "Takvimde gezinmek için ok tuşlarını kullanın"
        },
        de: {
          labelPrevDecade: "Vorheriges Jahrzehnt",
          labelPrevYear: "Vorheriges Jahr",
          labelPrevMonth: "Vorheriger Monat",
          labelCurrentMonth: "Aktueller Monat",
          labelNextMonth: "Nächster Monat",
          labelNextYear: "Nächstes Jahr",
          labelNextDecade: "Nächstes Jahrzehnt",
          labelToday: "Heute",
          labelSelected: "Ausgewähltes Datum",
          labelNoDateSelected: "Kein Datum gewählt",
          labelCalendar: "Kalender",
          labelNav: "Kalendernavigation",
          labelHelp: "Mit den Pfeiltasten durch den Kalender navigieren"
        },
        "ar-EG": {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "العقد السابق",
          labelPrevYear: "العام السابق",
          labelPrevMonth: "الشهر السابق",
          labelCurrentMonth: "الشهر الحالي",
          labelNextMonth: "الشهر المقبل",
          labelNextYear: "العام المقبل",
          labelNextDecade: "العقد القادم",
          labelToday: "اليوم",
          labelSelected: "التاريخ المحدد",
          labelNoDateSelected: "لم يتم اختيار تاريخ",
          labelCalendar: "التقويم",
          labelNav: "الملاحة التقويم",
          labelHelp: "استخدم مفاتيح المؤشر للتنقل في التواريخ"
        },
        zh: {
          weekdayHeaderFormat: "narrow",
          labelPrevDecade: "过去十年",
          labelPrevYear: "上一年",
          labelPrevMonth: "上个月",
          labelCurrentMonth: "当前月份",
          labelNextMonth: "下个月",
          labelNextYear: "明年",
          labelNextDecade: "下一个十年",
          labelToday: "今天",
          labelSelected: "选定日期",
          labelNoDateSelected: "未选择日期",
          labelCalendar: "日历",
          labelNav: "日历导航",
          labelHelp: "使用光标键浏览日期"
        }
      }
    };
  },
  watch: {
    value: {
      handler(newValue) {
        this.dateValue = newValue;
      },
      deep: true
    }
  },
  mounted() {
    this.dateValue = this.value;
  }
};
</script>

<style scoped></style>
