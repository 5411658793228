import Vue from "vue";
import { mapGetters } from "vuex";
import ApiService from "../services/api.service";
import i18n from "../plugins/vue-i18n";

export default Vue.mixin({
  methods: {
    apiSend(method, url, item) {
      return new Promise(function(resolve, reject) {
        ApiService.send(method, url, item)
          .then(res => {
            if (res.data.success) {
              resolve(res.data.data);
            } else {
              reject(res.data);
            }
          })
          .catch(error => {
            reject(error);
          });
      }).catch(error => {
        this.popAlert(error.message + "<br/>" + url, "error");
        throw error;
      });
    },
    apiSendGraph(moduleName, tableGraphqlQuery, sort, filters, page, rowCount) {
      let filterValues = [];
      if (typeof filters != "undefined" && typeof filters == "object") {
        let quote = "'",
          value = "";
        for (let key in filters) {
          if (
            filters[key].hasOwnProperty("value") &&
            filters[key]["value"] != null
          ) {
            value = filters[key].value;
            if (typeof value === "object" && value !== null) {
              value = value.value;
            }
            switch (filters[key].type) {
              case "string":
                if (value != "") {
                  if (filters[key].searchBy == "contains") {
                    value = "%" + value + "%";
                  } else if (filters[key].searchBy == "beginsWith") {
                    value = value + "%";
                  } else if (filters[key].searchBy == "endsWith") {
                    value = "%" + value;
                  } else if (filters[key].searchBy == "notEqual") {
                    value = "!=" + value;
                  }
                } else {
                  value = null;
                }
                break;
              case "bool":
                quote = "";
                break;
              case "number":
              default:
                if (filters[key].searchBy == "greaterThan") {
                  value = ">" + value;
                } else if (filters[key].searchBy == "greaterAndEqualThan") {
                  value = ">" + value;
                } else if (filters[key].searchBy == "lowerThan") {
                  value = "<" + value;
                } else if (filters[key].searchBy == "lowerAndEqualThan") {
                  value = "<=" + value;
                } else if (filters[key].searchBy == "notEqual") {
                  value = "!=" + value;
                }
                break;
            }
            // if (value != null) filter.push(key + ":" + quote + value + quote);
            if (value != null)
              filterValues.push(
                "'" +
                  (typeof filters[key].field !== "undefined"
                    ? filters[key].field
                    : key) +
                  "':" +
                  quote +
                  value +
                  quote
              );
          }
        }
      }
      let that = this;
      return new Promise(function(resolve) {
        that
          .apiSend("post", "graphql", {
            query: `query ($page:Int,$rowCount:Int,$sort:String){${moduleName}(${
              filterValues.length > 0
                ? 'filters:"{' + filterValues.join(",") + '}",'
                : ""
            }page:$page,rowCount:$rowCount,sort:$sort){count,page,rowCount,data{${tableGraphqlQuery}}}}`,
            variables: {
              page: typeof page == undefined || page <= 0 ? 0 : page,
              rowCount:
                typeof rowCount == undefined || rowCount <= 0 ? 0 : rowCount,
              sort: sort
            }
          })
          .then(data => {
            resolve(data[moduleName]);
          })
          .catch(() => {});
      });
    },
    apiGetCombo(
      moduleName,
      valueLabel,
      valueField,
      textLabel,
      textField,
      filters,
      sort
    ) {
      let that = this;
      return new Promise(function(resolve) {
        that
          .apiSendGraph(
            moduleName,
            `${valueLabel}: ${valueField}, ${textLabel}: ${textField}`,
            sort,
            filters
          )
          .then(data => {
            resolve(data["data"]);
          });
      });
    },
    apiGetDefinitionByTypeCode(definitionTypeCode) {
      let that = this;
      return new Promise(function(resolve) {
        that
          .apiSendGraph("definitionTypes", `definitions{id,code,name}`, null, {
            code: { value: definitionTypeCode }
          })
          .then(data => {
            resolve(data["data"][0]["definitions"]);
          });
      });
    },
    deleteFileFromServer(link) {
      return new Promise(function(resolve, reject) {
        ApiService.send("post", "deletefile", {
          file: link
        })
          .then(res => {
            if (res.data == "OK") resolve(link);
            else reject(res.data);
          })
          .catch(() => {});
      });
    },
    cloneSendItem(sourceObject) {
      const newObject = {};
      for (let key in sourceObject) {
        if (
          sourceObject.hasOwnProperty(key) &&
          typeof sourceObject[key] != "object"
        ) {
          newObject[key] = sourceObject[key];
        }
      }
      return newObject;
    },
    popConfirm(message, title) {
      let comp = this;
      return new Promise(function(resolve) {
        comp
          .$swal({
            title: message.replace("\r\n", "<br/>"),
            html:
              typeof title != "undefined" ? title : i18n.t("MESSAGES.QUESTION"),
            icon: "question",
            showCancelButton: true
          })
          .then(result => {
            if (result.value) {
              resolve();
            }
          });
      });
    },
    popAlert(message, type, title, timer) {
      type = typeof type != "undefined" ? type : "warning";
      let stitle, stimer;
      switch (type) {
        case "success":
          stitle = this.t("MESSAGES.SUCCESS");
          stimer = 1500;
          break;
        case "error":
          stitle = this.t("MESSAGES.ERROR");
          stimer = 2500;
          break;
        case "info":
          stitle = this.t("MESSAGES.INFO");
          stimer = 1500;
          break;
        case "question":
          stitle = this.t("MESSAGES.QUESTION");
          break;
        default:
          stitle = this.t("MESSAGES.WARNING");
          break;
      }
      this.$swal({
        html: message.replace("\r\n", "<br/>"),
        position: "top-end",
        icon: type,
        title: typeof title != "undefined" ? title : stitle,
        showConfirmButton: false,
        timer: typeof timer != "undefined" ? timer : stimer
      });
    },
    t(key) {
      let values = [],
        len = arguments.length - 1;
      while (len-- > 0) values[len] = arguments[len + 1];
      let i18n = this.$i18n;
      let t = i18n._t.apply(
        i18n,
        [key, i18n.locale, i18n._getMessages(), this].concat(values)
      );
      if (typeof t === "object") {
        return t.D;
      } else {
        return t;
      }
    },
    mylog() {
      // let debug = false;
      // if (debug) {
      //   let args = Array.prototype.slice.call(arguments);
      //   console.log.apply(console, args);
      // }
    },

    objectPropertyValueByString(o, s) {
      s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
      s = s.replace(/^\./, ""); // strip a leading dot
      var a = s.split(".");
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (o != null)
          if (k in o) {
            o = o[k];
          } else {
            return;
          }
      }
      return o;
    }
  },
  props: {
    hideFilters: Boolean,
    noBreadCrumb: Boolean
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["currentUser"])
  }
});
