import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import jwt from "jsonwebtoken";
import { SET_MENU } from "./navigation.store";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
let verifyTimeOut = null;
const state = {
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  /**
   * Her sf refresh de çalışır
   * apigateway de session sürüyorsa true, yoksa false döndürür
   */
  [VERIFY_AUTH](context) {
    ApiService.send("get", "login/verify")
      .then(res => {
        if (!res.data) {
          context.commit(SET_ERROR, res.data);
          context.commit(PURGE_AUTH);
        } else {
          /**
           * sayfa refresh yapılırsa, token decode edilerek,
           * içindeki user data, sonraki sf için init ediliyor
           */
          context.commit(SET_AUTH, jwt.decode(JwtService.getToken()));
          context.commit(SET_MENU, JwtService.getToken());
          clearTimeout(verifyTimeOut);
          verifyTimeOut = setTimeout(function() {
            context.dispatch(VERIFY_AUTH);
          }, 60000);
        }
      })
      .catch(error => {
        context.commit(SET_ERROR, error);
        context.commit(PURGE_AUTH);
      });
  },
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.send("post", "login", credentials)
        .then(res => {
          if (res.data.success) {
            /**
             * Kullanıcı datasında, apigatewayden dönen token bulunur. haline dönüştürülmüş
             * token, kullanıcı objesinin tokenize edilmiş halidir.
             * Bu data sf refreshlerinde decode edilerek sonraki sf ya aktarılır.
             */
            JwtService.saveToken(res.data.data.token);
            ApiService.setToken();
            context.commit(SET_AUTH, res.data.data);
            context.commit(SET_MENU, res.data.data.token);
            resolve(res.data.data);
          } else {
            context.commit(SET_ERROR, res.data);
            reject(res.data);
          }
        })
        .catch(error => {
          context.commit(SET_ERROR, error);
          reject(error);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    alert("Unimplemented yet!");
    return new Promise((resolve, reject) => {
      ApiService.post("register", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response);
          reject(response);
        });
    });
  },
  [UPDATE_USER](context, payload) {
    alert("Unimplemented yet!");
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors.push(error);
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = [];
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = [];
    JwtService.destroyToken();
    ApiService.send("get", "logout").catch(() => {});
    // .then(()=>location.reload())
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
