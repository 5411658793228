import Vue from "vue";
import Router from "vue-router";
import JwtService from "@/services/jwt.service";
import jwt from "jsonwebtoken";

Vue.use(Router);

let options = {
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login"
    },
    {
      path: "/",
      name: "logout"
    },
    {
      path: "/test",
      name: "test",
      component: () => import("@/views/pages/Test.vue")
    },

    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/layouts/Base"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/views/pages/Dashboard.vue")
        },
        {
          path: "/builder",
          name: "builder",
          component: () => import("@/views/pages/Builder.vue")
        },
        {
          path: "/panelsettings/:id",
          name: "panelsettings",
          component: () => import("@/views/pages/PanelSettings.vue")
        },
        {
          path: "/polygon",
          name: "polygon",
          component: () => import("@/views/pages/Polygon.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/layouts/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/views/pages/auth/Login")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/views/pages/error/Error.vue")
    }
  ]
};
if (JwtService.getToken()) {
  let userData = jwt.decode(JwtService.getToken());
  // if (userData && userData.role && userData.role.roleModules) {
  let roleModules = userData.role.roleModules;
  let dynamicRoutes = [];
  roleModules.forEach(item => {
    if (item.get) {
      dynamicRoutes.push({
        name: item.module.endPoint.toLowerCase(),
        path: item.module.endPoint.toLowerCase(),
        component: () =>
          import(
            "@/views/pages/api/" +
              item.module.moduleGroup.code.toLowerCase() +
              "/" +
              item.module.endPoint.toLowerCase()
          )
      });
    }
    if (item.put) {
      dynamicRoutes.push({
        name: item.module.endPoint.toLowerCase() + "/:id",
        path: item.module.endPoint.toLowerCase() + "/:id",
        component: () =>
          import(
            "@/views/pages/api/" +
              item.module.moduleGroup.code.toLowerCase() +
              "/" +
              item.module.endPoint.toLowerCase()
          )
      });
    }
  });
  options.routes.push({
    path: "/",
    component: () => import("@/layouts/Base"),
    children: dynamicRoutes
  });
  // }
}
export default new Router(options);
