// TR
/*
    D DEFAULT, SINGULAR
    P PLURAL
    S SHORT
    SP SHORT PLURAL
 */
export const locale = {
  GLOBAL: {
    ABOUT: "Hakkımızda",
    ACCOUNT: "Kişi",
    ACTIONS: "Seçenekler",
    ACTIONTYPE: {
      D: "Ekim Alanı Aksiyonu",
      P: "Ekim Alanı Aksiyonları",
      S: "Aksiyon Tipi",
    },
    ACTIONPRODUCT: "İşlemde kullanılan ürün",
    ACTIVE: "Aktif",
    ACTIVITY: "Aktivite",
    ACTIVITYHISTORY: "Aktivite Tarihçesi",
    ADD: "Ekle",
    ADDRESS: "Adres",
    AGEOFTREES: "Ağaç Yaşı",
    ALL: "Tümü",
    AMOUNT: "Miktar",
    PIECE: "Adet",
    APIUSERS: {
      D: "API Kullanıcısı",
      P: "API Kullanıcıları",
    },
    AREA: "Alan",
    AREATKGM: "TKGM Alan",
    BASEPRICE: "Baz Fiyat",
    BASEPRICES: {
      D: "Baz Fiyat",
      P: "Baz Fiyatlar",
    },
    BIRTHDAY: "Doğum Tarihi",
    BIO: "Hakkında",
    BUYERCOMMENTS: {
      D: "Alıcı Yorumu",
      P: "Alıcı Yorumları",
    },
    BUYERS: {
      D: "Alıcı",
      P: "Alıcılar",
    },
    BUYERTYPE: "Alıcı Tipi",
    CANCEL: "İptal",
    CHANGEPASSWORD: "Değiştir",
    CITY: {
      D: "İl",
      P: "İller",
    },
    CLICKIMAGESTOUPLOAD: "...veya yüklemek için burayı tıklayın",
    CLOSE: "Kapat",
    CODE: "Kod",
    COMMENT: {
      D: "Yorum",
      P: "Yorumlar",
    },
    COMMERCIALINFO: "Ticari Bilgiler",
    COMPANY: {
      D: "Firmalar",
      P: "Firmalar",
    },
    COMPANIES: {
      D: "Firma",
      P: "Firmalar",
    },
    COMPANYNAME: "Firma Adı",
    CONFIRMDATE: "Onay Tarihi",
    CONTACT: "İletişim",
    CONTACTINFO: "İletişim Bilgileri",
    CONTENT: "İçerik",
    CONSULTANTCOMMENTS: {
      D: "Danışman Yorumu",
      P: "Danışman Yorumları",
    },
    CONSULTANTS: {
      D: "Danışman",
      P: "Danışmanlar",
    },
    CONSULTANTFARMERRELATIONS: {
      D: "Danışman Çiftçi İlişkisi",
      P: "Danışman Çiftçi İlişkileri",
    },
    CONSULTINGPRICE: "Danışman Fiyatı",
    CONTACTNAME: "İlgili Kişi",
    COORDINATES: "Koordinatlar",
    CORPORATE: "Kurumsal",
    COUNTS: "Sayılar",
    COUNTRY: {
      D: "Ülke",
      P: "Ülkeler",
    },
    COUNTY: {
      D: "İlçe",
      P: "İlçeler",
    },
    CROPLAND: {
      D: "Ekim Alanı",
      P: "Ekim Alanları",
    },
    CROPLANDS: {
      D: "Ekim Alanı",
      P: "Ekim Alanları",
    },
    CROPLANDACTIONS: {
      D: "Ekim Alanı Aksiyonu",
      P: "Ekim Alanı Aksiyonları",
    },
    CROPLANDCOMMENTS: {
      D: "Ekim Alanı Yorumu",
      P: "Ekim Alanı Yorumları",
    },
    CROPLANDTYPE: {
      D: "Ekim Alanı Tipi",
      P: "Ekim Alanı Tipleri",
      S: "Alan Tipi",
    },
    DATE: {
      D: "Tarih",
      P: "Tarihler",
    },
    DASHBOARD: "Yönetim Paneli",
    DECARE: "Dekar",
    DEFAULT: "Varsayılan",
    DEFINITIONS: {
      D: "Tanım",
      P: "Tanımlar",
    },
    DEFINITIONTYPES: {
      D: "Tanım Tipi",
      P: "Tanım Tipleri",
    },
    DEFINITIONTYPE: "Tanım Tipi",
    DELETE: "Sil",
    DELIVERYADDRESSES: "Teslimat Adresleri",
    DESCRIPTION: {
      D: "Açıklama",
      P: "Açıklamalar",
    },
    DENIZBANKREQUESTS: {
      D: "Denizbank Başvurusu",
      P: "Denizbank Başvuruları",
    },
    DETAILS: "Detaylar",
    DROPFILESTOUPLOAD: "Dosya yüklemek için buraya sürükleyin",
    DROPIMAGESTOUPLOAD: "Resim yüklemek için buraya sürükleyin",
    DISTRICT: {
      D: "Mahalle",
      P: "Mahalleler",
    },
    EDIT: "{name} Düzenleme",
    EMAIL: "E-posta",
    EMAILALLOWED: "E-Posta Gönderilebilir",
    END: "Bitiş",
    ENDDATE: "Bitiş Tarihi",
    ENDPOINT: {
      D: "Endpoint",
      P: "Endpoints",
    },
    ENDPOINTS: {
      D: "Endpoint",
      P: "Endpointler",
    },
    EOSREPORTS: "EOS Raporları",
    EXPORT: "Export",
    FARMER: {
      D: "Çiftçi",
      P: "Çiftçiler",
    },
    FARMERS: {
      D: "Çiftçi",
      P: "Çiftçiler",
    },
    FARMERCOMMENTS: {
      D: "Çiftçi Yorumu",
      P: "Çiftçi Yorumları",
    },
    FEE: "Kullanım Bedeli",
    FILE: "Dosya",
    FILEMANAGER: "Dosya Yöneticisi",
    FILTER: {
      D: "Filtre",
      P: "Filtreler",
    },
    FOOTER: "Footer",
    FORGOTPASSWORD: "Şifrenizi mi unuttunuz??",
    FULLNAME: "Ad Soyad",
    GET: "Görüntüle",
    GROUP: {
      D: "Grup",
      P: "Gruplar",
    },
    GREETINGS: "Hoşgeldiniz",
    HARVEST: {
      D: "Hasat",
      P: "Hasatlar",
    },
    HARVESTCOMMENTS: {
      D: "Hasat Yorumu",
      P: "Hasat Yorumları",
    },
    HARVESTS: {
      D: "Hasat",
      P: "Hasatlar",
    },
    HARVESTREQUESTS: {
      D: "Talep",
      P: "Talepler",
    },
    HARVESTREQUESTACTIVITYDEFINITIONS: {
      D: "Talep Aktivite Tanımı",
      P: "Talep Aktivite Tanımları",
    },
    HEADER: "Başlık",
    HELPFUL: "Faydalı",
    ICON: "İkon",
    ID: "ID",
    IDENTIFYNO: "Kimlik Numarası",
    IMAGE: "Resim",
    IMAGEEDITOR: "Resim Düzenleyici",
    INDIVIDUAL: "Bireysel",
    INFO: "Bilgiler",
    IPADDRESS: {
      D: "IP Adresi",
      P: "IP Adresleri",
    },
    ITEM_ADD: "Yeni Kayıt",
    ITEM_EDIT: "Düzenle",
    ITEM_DELETE: "Sil",
    KEY: "Anahtar",
    KIND: "Tür",
    LAND: {
      D: "Arazi",
      P: "Araziler",
    },
    LANDCOMMENTS: {
      D: "Arazi Yorumu",
      P: "Arazi Yorumları",
    },
    LANDS: {
      D: "Arazi",
      P: "Araziler",
    },
    LANDNAME: "Arazi Adı",
    LANDSINFO: "Arazi Bilgileri",
    LANDTYPE: "Arazi Tipi",
    LANGUAGE: {
      D: "Dil",
      P: "Diller",
    },
    LANGUAGES: {
      D: "Dil",
      P: "Diller",
    },
    LIST: "Liste",
    LOADING: "Yükleniyor",
    LOCATION: {
      D: "Konum",
      P: "Konumlar",
    },
    LOCATIONS: {
      D: "Lokasyon",
      P: "Lokasyonlar",
    },
    LOGINBUTTON: "Giriş Yap",
    LOGISTICCOMPANYCOMMENTS: {
      D: "Lojistik Firması Yorumu",
      P: "Lojistik Firması Yorumları",
    },
    LOGISTICS: "Lojistik",
    LOGISTICCOMPANIES: {
      D: "Lojistik Firması",
      P: "Lojistik Firmaları",
    },
    MODULE: {
      D: "Modül",
      P: "Modüller",
    },
    MODULES: {
      D: "Modül",
      P: "Modüller",
    },
    MODULEGROUP: {
      D: "Modül Grubu",
      P: "Modül Grupları",
    },
    MODULEGROUPS: {
      D: "Modül Grubu",
      P: "Modül Grupları",
    },
    NAME: "Ad",
    NEW: "Yeni {name}",
    NEIGHBORHOOD: "Mahalle",
    NOCRECORD: "Kayıt Yok",
    NOOFTREES: "Ağaç Sayısı",
    NOTE: "Not",
    NOTIFICATIONS: {
      D: "Bildirim",
      P: "Bildirimler",
    },
    NOTIFICATIONTEMPLATES: {
      D: "Bildirim Şablonu",
      P: "Bildirim Şablonları",
    },
    OFFLINE: "Offline",
    ONLINE: "Online",
    PAGE: "Sayfa",
    PANELSETTINGS: "Panel Ayarları",
    PACKINGPRICE: "Paketleme Fiyatı",
    PASSIVE: "Pasif",
    PASSWORD: "Şifre",
    PASSWORDCONFIRM: "Şifre Tekrar",
    PARENT: "Üst",
    PERSONALINFO: "Kişisel Bilgiler",
    PICKINGPRICE: "Toplama Fiyatı",
    PHONE: "Telefon",
    PLEASESELECT: "Seçiniz",
    PLOT: "Pafta",
    PLOTID: "Pafta Id",
    PLACE: "Mevki",
    PRODUCT: {
      D: "Ürün",
      P: "Ürünler",
    },
    PRODUCTS: {
      D: "Ürün",
      P: "Ürünler",
    },
    PRODUCTNAME: "Ürün Adı",
    PRODUCTKIND: {
      D: "Ürün Cinsi",
      P: "Ürün Cinsleri",
    },
    PRODUCTKINDS: {
      D: "Ürün Cinsi",
      P: "Ürün Cinsleri",
    },
    PRODUCTPRICES: "Ürün Fiyatları",
    PROFILEIMAGE: "Profil Resmi",
    PROFILECOVERIMAGE: "Profil Kapak Resmi",
    PROFILETEXT: "Profil Yazısı",
    POST: "Yeni",
    PUT: "Güncelle",
    RATING: "Derece",
    RATEOFWASTE: "Fire Oranı",
    RECENTACTIVITIES: "Son Aktiviteler",
    MAPTITLE: "Kayıtlı Araziler",
    TOTALHARVESTS: "Toplam Hasat",
    HARVESTPRODUCT: "Ürünlere göre Hasatlar",
    TOPPRODUCTSYEAR: "Yıllara göre Hasatlar",
    REFRESH: "Yenile",
    REORDER: "Sırala",
    RESET: "Sıfırla",
    RESPONSE: "Cevap",
    REQUEST: "Talep",
    REQUESTDATE: "Talep Tarihi",
    REQUESTEDAMOUNT: "Talep edilen miktar",
    ROLE: {
      D: "Rol",
      P: "Roller",
    },
    ROLES: {
      D: "Rol",
      P: "Roller",
    },
    ROUNDABOUT: "Ada",
    ROLEMODULES: "Rol Modulleri",
    RUNMETHOD: "Çalıştırılacak Metod",
    RTL: "Rtl",
    SAVE: "Kaydet",
    SAVEALL: "Tümünü Kaydet",
    SELECTLANGUAGE: "Dil Seç",
    SENDPASSWORDRESETCODE: "Sıfırlama iletisi gönder",
    SETTINGS: {
      D: "Ayar",
      P: "Ayarlar",
    },
    SIGNINMANAGER: "Yönetici Girişi",
    SIGNIN: "Kullanıcı Girişi",
    SIGNOUT: "Çıkış",
    SHEET: "Parsel",
    SHORTCODE: "Kısa Kod",
    SMS: "SMS",
    SMSALLOWED: "SMS Gönderilebilir",
    STATUS: "Durum",
    START: "Başlangıç",
    STARTDATE: "Başlangıç Tarihi",
    STATISTICS: "İstatistikler",
    SUBSTATUS: "Alt Durum",
    TAGS: "Etiketler",
    TARGETSTATUS: "Hedef Durum",
    TARGETSUBSTATUS: "Hedef Alt Durum",
    TAXNO: "Vergi No",
    TAXOFFICE: "Vergi Dairesi",
    TEMPLATE: "Şablon",
    TEST: "TEST",
    TITLE: "Başlık",
    TREEGAP: "Ağaç aralığı",
    TREECOUNT: "Ağaç sayısı",
    TYPE: {
      D: "Tip",
      P: "Tipler",
    },
    UNHELPFUL: "Faydasız",
    UNITPRICE: "Birim Fiyatı",
    UPLOADIMAGE: "Resim Yükle",
    UPLOAD: "Yükle",
    UPLOADTITLE: "Dosya Yükle",
    UPLOADSUBTITLE: "Resim",
    USERS: {
      D: "Kullanıcı",
      P: "Kullanıcılar",
    },
    WEBSITE: "Web Sitesi",
    VALUE: "Değer",
    VISIBLE: "Görünür",
    ZIPCODE: "Posta Kodu",
    MONTHS: {
      JANUARY: "Ocak",
      FEBRUARY: "Şubat",
      MARCH: "Mart",
      APRIL: "Nisan",
      MAY: "Mayıs",
      JUNE: "Haziran",
      JULY: "Temmuz",
      AUGUST: "Ağustos",
      SEPTEMBER: "Eylül",
      OCTOBER: "Ekim",
      NOVEMBER: "Kasım",
      DECEMBER: "Aralık",
    },
  },
  MESSAGES: {
    ADDED: "Eklendi...",
    CONFIRM: "Emin misiniz?",
    DELETECONFIRMTEXT: "Bu Kayıt kalıcı olarak silinecektir, emin misiniz?",
    DELETED: "Silindi...",
    ERROR: "Bir sorun oluştu",
    FORMHASERRORS: "Formda hatalar var",
    INDEXERROR: "Kayıt bulunamadı (Index Hatası)",
    INFO: "Uyarı",
    QUESTION: "Soru",
    REQUIREDFIELD: "{fieldname} gerekli",
    ROUTEERROR: "Endpoint Hatası",
    SENDED: "Gönderildi",
    SUCCESS: "Başarılı",
    UPDATED: "Güncellendi...",
    USERNOTFOUND: "Kullanıcı bulunamadı",
    WARNING: "Uyarı",
  },
  VALIDATIONS: {
    STRONGPASSWORD:
      "{0} en az 1 büyük harf, 1 küçük harf, 1 rakam ve 1 özel karakter içermelidir.(Ör. , . _ & ? vs..)",
  },
};
