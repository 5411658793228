<template>
  <div>
    <vue-tel-input
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      v-bind="options"
      @validate="validate"
    />
  </div>
</template>

<script>
export default {
  name: "IntlTel",
  props: {
    id: null,
    value: {
      type: String,
      default: ""
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      phone: "",
      bindOptions: {
        mode: "international",
        defaultCountry: "FR",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter a phone number",
        required: false,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: ["TR", "RU"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 25,
        wrapperClasses: "",
        inputClasses: "form-control",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: true
        },
        ...this.options
      }
    };
  },
  methods: {
    change(val) {
      this.$emit("change", val);
    },
    validate(event) {
      this.phone =
        typeof event.number.international != "undefined"
          ? event.number.international
          : "";
    }
  },
  created() {
    this.phone = typeof this.value != "undefined" ? this.value : "";
  }
};
</script>

<style scoped></style>
