<template>
  <b-form-input
    type="number"
    v-bind:value="value"
    v-on:input="convertToNumber"
    step="any"
    placeholder="0.00"
    no-wheel
  ></b-form-input>
</template>

<script>
export default {
  name: "DecimalInput",
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  methods: {
    convertToNumber(number) {
      this.$emit("input", parseFloat(number.replace(",", ".")));
    }
  }
};
</script>

<style scoped></style>
